angular.module('huni').controller('CMSController', [
    '$scope', '$timeout', 'HuniBackend',
function ($scope, $timeout, huniBackend) {

    $scope.documents = [ ];
    $scope.content = "";
    $scope.original = "";
    $scope.modified = false;
    $scope.history = [ ];
    $scope.selectedDoc = undefined;
    $scope.alertMessage = undefined;
    $scope.options = {
      toolbar: [
        ['style', ['style']],
        ['font', ['bold', 'italic', 'underline', 'clear']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['table', ['table']],
        ['insert', ['link', 'picture', 'video', 'hr']],
        ['view', ['fullscreen', 'codeview']],
        ['help', ['help']]
      ],
    };

    var alertTimeoutMillis = 2500;
    var alertId = 0;

    function showAlert(type, message) {
        $scope.alertType = type;
        $scope.alertMessage = message;

        // Each alert gets it's own version of alertId. In the case of two
        // overlapping alerts, this stops the early one from clearing the later
        // one.
        var myAlertId = ++alertId;
        $timeout(function() {
            if (alertId === myAlertId) {
                $scope.alertMessage = undefined;
            }
        }, alertTimeoutMillis);
    }

    function loadHistory() {
        var id = $scope.selectedDoc;
        huniBackend
            .get('cms/history/' + id)
            .success(function(history) {
                $scope.history = history;
            })
            ;
    };

    $scope.load = function() {
        var id = $scope.selectedDoc;
        huniBackend
            .get('cms/' + id)
            .success(function(content) {
                $scope.original = content;
                $scope.content = content;
                $scope.modified = false;
            })
            ;
        loadHistory();
    };

    $scope.save = function() {
        huniBackend
            .put('cms/' + $scope.selectedDoc, { content: $scope.content })
            .success(function(result) {
                $scope.original = $scope.content;
                $scope.modified = false;
                loadHistory();
                showAlert('success',
                    "Saved " + $scope.selectedDoc + ".");
            })
            .error(function() {
                showAlert('danger',
                    "There was an error saving " + $scope.selectedDoc + ".");
            })
            ;
    };

    $scope.revert = function() {
        $scope.content = $scope.original;
        showAlert('info', "Reset " + $scope.selectedDoc + " to version " +
                          $scope.history[0].version + ".");
    };

    $scope.revertTo = function(version) {
        var id = $scope.selectedDoc;
        huniBackend
            .get('cms/' + id + '/' + version)
            .success(function(content) {
                $scope.content = content;
                showAlert('info',
                    "Reset " + id + " to version " + version + ".");
            });
    };

    $scope.closeAlert = function() {
        $scope.alertMessage = undefined;
    }

    $scope.$watch('content', function(newValue, oldValue) {
        $scope.modified = ($scope.original !== $scope.content);
    });

    $scope.$watch('selectedDoc', function(newValue, oldValue) {
        if (!_.isUndefined(newValue)) {
            $scope.load();
        }
    });

    window.onbeforeunload = function() {
        if (!$scope.modified) { return; }
        return 'Your changes have not been saved';
    };

    $scope.$on('$locationChangeStart', function (event, next, current) {
        if ($scope.modified) {
            var answer = confirm('Your changes have not been saved.\n\n' +
                                 'Are you sure you want to leave this page?');
            if (!answer) {
                event.preventDefault();
            }
        }
    });

    huniBackend
        .get('cms')
        .success(function(data) {
            $scope.documents = data;
            $scope.selectedDoc = data[0];
        })
        .error(function() {
            console.log(arguments);
            showAlert('danger',
                'Failed to load CMS document list. Try again later?');
        })
        ;
}]);
