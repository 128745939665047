angular.module('huni').controller('NetworkController', [
    '$scope', '$location', '$routeParams', '$q',
    'GraphQueryService', 'SolrSearchService', 'HuniBackend', 'DialogService',
function ($scope, $location, $routeParams, $q, graphQuery, solr, huni, dialog) {

/*

TODO:

1. Create a hash of all 'expanded' records and collections.
2. Use this as a cache when fetching record info.

This will allow us to:
3. Pre-fetch leaf nodes as soon as they appear in the graph

The idea is that each leaf node already has all its links
pre-populated, and when the user activates that node, we can
immediately populate it, and then do fetch on the new leaf nodes.

 */

    // Need this to shut jslint up
    /*global setFocusRecord: false */
    var collectionPrefix = 'HuNI***Collection***';

    $scope.activeEdges = [ ];

    function showLinkDialog(link) {
        return dialog.linkDialog(link);
    }

    // Make a collection object look like a record
    function recordiseCollection(c) {
        c.entityType = 'Collection';
        c.docid = collectionPrefix + c.id;
        c.displayName = c.name;
        var prefix = $scope.user.owns(c) ? '#/myhuni' : '#';
        c.link = prefix + '/collection/' + c.id;
        c.description = c.synopsis;
    }

    function nodeSize(d) {
        var scale = 1.8;
        var base = 30;
        return d.docid === $scope.record.docid ? base * scale : base;
    }

    function getGraphSize() {
        var container = document.getElementById('main-container');
        var width = container.offsetWidth - 100,
            height = window.innerHeight * 0.6;

        return { width: width, height: height };
    }

    function translate(x, y) {
        return "translate(" + x + "," + y + ")";
    }

    function linkTransform(link) {
        return translate(
            (link.source.x + link.target.x) * 0.5,
            (link.source.y + link.target.y) * 0.5
        );
    }

    function onGraphTick() {
        $scope.edge.attr("x1", function(d) { return d.source.x; })
                   .attr("y1", function(d) { return d.source.y; })
                   .attr("x2", function(d) { return d.target.x; })
                   .attr("y2", function(d) { return d.target.y; })
                   ;

        $scope.node
            .attr("xlink:href", function(d) {
                var file = d.entityType.toLowerCase();
                if (!d.loaded) {
                    file += '-unloaded';
                }
                var href = 'images/graph/' + file + '.svg';
                return href;
            })
            .attr("height", nodeSize)
            .attr("width",  nodeSize)
            .attr("transform", function(d) {
                var sz = nodeSize(d) / 2;
                return translate(d.x - sz, d.y - sz);
            })
            ;

        $scope.nodeLabels
            .attr("x", function(d) { return 5 + nodeSize(d) / 2; })
            .attr("transform", function(d) { return translate(d.x, d.y); })
            ;

        $scope.edgeLabels
            .attr("transform", linkTransform)
            ;
    }

    function resizeGraph() {
        // http://stackoverflow.com/questions/16265123/resize-svg-when-window-is-resized-in-d3-js
        var size = getGraphSize();

        // set attrs and 'resume' force
        $scope.svg.attr('width', size.width);
        $scope.svg.attr('height', size.height);
        $scope.graph.size([size.width, size.height]).resume();
    }

    function isOffScreen(x, y) {
        if (x < 0) { return true; }
        if (y < 0) { return true; }

        var size = $scope.graph.size();
        if (x >= size[0]) { return true; }
        if (y >= size[1]) { return true; }
        return false;
    }

    function initGraph() {
        var size = getGraphSize();

        var graph = d3.layout.force();
        graph.charge(-800)
             .friction(0.75)
             .linkDistance(100)
             .linkStrength(1)
             .nodes($scope.nodes)
             .links($scope.edges)
             .size([size.width, size.height]);
        graph.on("tick", onGraphTick);

        $scope.graph = graph;

        var svg = d3.select("#graph").append("svg");
        svg.attr("width", size.width)
           .attr("height", size.height)
           ;

        $scope.svg = svg;

        // The "g" here is important. It groups the links and nodes together
        // into their own objects. This way all the nodes are drawn after all
        // the links. If we don't have these, a new link draws on top of
        // everything.
        $scope.edge = svg.append("g").selectAll();
        $scope.node = svg.append("g").selectAll();
        $scope.nodeLabels = svg.append("g").selectAll();
        $scope.edgeLabels = svg.append("g").selectAll();

        $scope.drag = graph.drag()
            .on("dragend", function(d) {
                // Un-fix nodes that have been dragged off-screen
                if (d.fixed && isOffScreen(d.x, d.y)) {
                    d.fixed = false;
                }
            });

        d3.select(window).on('resize', resizeGraph);
    }

    function makeLink(linkData) {
        let link = _.clone(linkData);

        function swap(object, key0, key1) {
            let t = object[key1];
            object[key1] = object[key0];
            object[key0] = t;
        }

        if (link.from_docid != $scope.record.docid) {
            swap(link, 'from_docid',  'to_docid');
            swap(link, 'linktype_id', 'pairtype_id');
            swap(link, 'linktype',    'pairtype');
        }

        delete link.record;
        link.from = $scope.nodeMap[link.from_docid];
        link.to   = $scope.nodeMap[link.to_docid];

        return link;
    }

    function updateEdgeLabels() {
        /* disabled for now
        $scope.edgeLabels = $scope.edgeLabels.data($scope.activeEdges);
        $scope.edgeLabels.enter()
            .append("text")
              .attr('class', 'graph-text edge-label')
              .attr("x", function(d) { return 3 + nodeSize(d) / 2; })
              .attr("y", ".35em")
              .attr("transform", linkTransform)
              .text(function(d) {
                  let relationship = (d.link.from_docid == $scope.record.docid)
                        ? d.link.linktype : d.link.pairtype;
                  let words = [
                      d.source.displayName,
                      relationship.toLowerCase(),
                      d.target.displayName,
                  ];
                  if (d.source.docid != $scope.record.docid) {
                      words.reverse();
                  }

                  return words.join(' ');
              })
            ;
        $scope.edgeLabels.exit().remove();
        */
    }

    function drawGraph() {
        $scope.edge = $scope.edge.data($scope.graph.links());
        $scope.edge.enter()
            .append("line", ".node")
            .attr("class", function(d) {
                if (d.link.user_id === 1) {
                    return "graph-link system";
                }
                if (d.source.entityType === 'Collection') {
                    return "graph-link collection";
                }
                if (d.target.entityType === 'Collection') {
                    return "graph-link collection";
                }
                return "graph-link"
            })
            .attr("id", function(d) {
                return "link-" + d.id;
            })
            .on('mouseover', function(d) {
                d.source.fixed = d.target.fixed = true;
                let edge = d3.select(this).classed("active", true);
                $scope.activeEdges = [ d ];
                updateEdgeLabels();
            })
            .on('mouseout', function(d) {
                let edge = d3.select(this).classed("active", false);
                d.source.fixed = d.target.fixed = false;
                if ($scope.activeEdges[0] == d) {
                    $scope.activeEdges = [ ];
                    updateEdgeLabels();
                }
            })
            .on('click', function(d) {
                let link = makeLink(d.link);
                showLinkDialog(link);
            })
            ;
        $scope.edge.exit().remove();
        $scope.node = $scope.node.data($scope.graph.nodes());

        $scope.node.enter()
            .append("image", ".node")
            .call($scope.drag)
            .on("click", function(d) {
                 if (d3.event.defaultPrevented) { return; } // ignore drag
                 setFocusRecord(d);
             });
        $scope.node.exit().remove();

        $scope.nodeLabels = $scope.nodeLabels.data($scope.graph.nodes());
        $scope.nodeLabels.enter()
            .append("text")
            .attr("class", "graph-text node-label")
            .attr("y", ".35em")
            .text(function(d) {
                return d.displayName || d.key;
            })
            ;
        $scope.nodeLabels.exit().remove();

        $scope.edgeLabels = $scope.edgeLabels.data($scope.activeEdges);
        $scope.edgeLabels.enter()
            .append("text")
            .attr('class', 'graph-text edge-label')
            .attr("y", ".35em")
            .text(function(d) {
                return d.key;
            })
            ;
        $scope.edgeLabels.exit().remove();

        $scope.graph.start();
    }

    function recordPos() {
        var isFirstNode = ($scope.nodes.length === 0);
        var size = $scope.graph.size();

        // Spread is betwen 0 and 1
        var spread = isFirstNode ? 0 : 0.2;

        function r() {
            return (Math.random() - 0.5) * spread + 0.5;
        }

        return {
            x: size[0] * r(), y: size[1] * r(),
        };
    }

    function addNode(record) {
        var node = $scope.nodeMap[record.docid];
        if (node) {
            return node;
        }

        var pos = record.initialPos || recordPos();
        record.px = record.x = pos.x;
        record.py = record.y = pos.y;
        $scope.nodes.push(record);
        $scope.nodeMap[record.docid] = record;

        return record;
    }

    function edgeKey(link) {
        if (link.from_docid < link.to_docid) {
            return link.from_docid + '->' + link.to_docid;
        }
        else {
            return link.to_docid + '->' + link.from_docid;
        }
    }

    function makeEdge(source, target, link) {
        if (source.docid > target.docid) {
            var t = source;
            source = target;
            target = t;
        }
        var key = source.docid + '->' + target.docid;
        return { key: key, source: source, target: target, link: link };
    }

    function addEdge(source, target, link) {
        var edge = makeEdge(source, target, link);
        var oldEdge = $scope.edgeMap[edge.key];
        if (oldEdge) { return oldEdge; }

        $scope.edges.push(edge);
        $scope.edgeMap[edge.key] = edge;

        return edge;
    }

    function removeEdge(source, target) {
        var key = makeEdge(source, target).key;
        var edge = $scope.edgeMap[key];
        if (!edge) { return false; }

        var index = _.indexOf($scope.edges, edge);
        $scope.edges.splice(index, 1);
        delete $scope.edgeMap[key];
        return true;
    }

    function updateGraph(record) {
        if (!_.has(record, 'displayName')) {
            return;
        }
        if (!$scope.graph) {
            initGraph();
        }
        record.loaded = true;
        var source = addNode(record);
        _.each(record.links, function(link) {
            var target = addNode(link.record);
            addEdge(source, target, link);
        });
        _.each(_.values(record.collections), function(collection) {
            var target = addNode(collection);
            addEdge(source, target, makeCollectionLink(collection, source));
        });
        drawGraph();
    }

    function makeCollectionLink(collection, record) {
        return {
            linktype: 'contains',
            pairtype: 'contained in',
            user: {
                name: record.username,
                id: record.user_id,
            },
            from_docid: collectionPrefix + collection.id,
            to_docid: record.docid,
            record: record,
        };
    }

    // Get the records for a collection, and then get the solr
    // data for those records. Returns a promise.
    function getCollectionInfo(collection) {
        let  p = huni.get('publication/' + collection.id);
        p = p.then(function(resp) {
                var c = resp.data;
                if (!_.has(collection, 'displayName')) {
                    collection.name      = c.name;
                    collection.synopsis  = c.synopsis;
                    collection.is_public = c.is_public;
                    collection.created_utc = c.created_utc;
                    collection.modified_utc = c.modified_utc;
                    recordiseCollection(collection);
                }
                var records = c.records;
                collection.links = _.map(records, function(r) {
                    return makeCollectionLink(c, r);
                });
                return solr.augmentRecords(records);
            });
        return p.then(function() { updateGraph(collection); });
    }

    // Gets the links for a record, and populates those with
    // record fields. Returns a promise.
    function getLinksForRecord(record) {
        return huni.get('/record/' + record.docid + '/link')
                .then(function (resp) {
                    record.links = resp.data;
                    record.linkCount = record.links.length;
                    var linkedRecords = _.map(record.links,
                        function(link) {
                            link.record = { docid: link.to_docid };
                            return link.record;
                        });
                    return solr.augmentRecords(linkedRecords);
                });
    }

    // Gets the collections for a record. Returns a promise.
    function getCollectionsForRecord(record) {
        return huni.get('/record/' + record.docid + '/collection')
                     .success(function (collections) {
                        _.each(collections, recordiseCollection);
                        record.collections = _.indexBy(collections, 'id');
                    });
    }

    // For a record, gets the record info, the links and
    // the collections. Once those are all available, redraw the
    // graph.
    function getRecordInfo(record) {
        var promises = [ ];
        if (!_.has(record, 'displayName')) {
            promises.push(solr.augmentRecords([ record ]));
        }
        promises.push(getLinksForRecord(record));
        promises.push(getCollectionsForRecord(record));

        var all = $q.all(promises);
        return all.then(function() { updateGraph(record); });
    }

    function setFocusRecord(record) {
        // Alway fix the focused record, even if it was already focused.
        // It may have been dragged off-screen and un-fixed.
        record.fixed = true;
        if ($scope.record === record) {
            return;
        }
        $scope.record.fixed = false;
        $scope.record = record;

        var path = '/record/' + record.docid;
        $location.sneakyPath(path);
        //drawGraph();

        if (record.entityType === 'Collection') {
            getCollectionInfo(record)
              .then(updateAddThisInfo);
        }
        else {
            getRecordInfo(record)
              .then(updateAddThisInfo);
        }
    }

    function updateLink(source_docid, target_docid, link, doAdd) {
        var source = $scope.nodeMap[source_docid];
        if (!source) { return; }

        var target = $scope.nodeMap[target_docid];
        if (!target) { return; }

        if (doAdd) {
            addEdge(source, target, link);
        }
        else {
            removeEdge(source, target);
        }

        drawGraph();
    }

    $scope.$on('LinkChangedEvent', function(event, data) {
        // Update the graph links
        updateLink(data.link.from_docid,
                   data.link.to_docid,
                   data.link,
                   data.action === 'created');

        // Update the links data if this is a new link and it involves the
        // selected record. This will update the link table properly.
        if ((data.action === 'created') &&
            ((data.link.to_docid === $scope.record.docid) ||
             (data.link.from_docid === $scope.record.docid))) {
            getLinksForRecord($scope.record)
                .then(function() { updateGraph($scope.record) });
        }
    });

    $scope.$on('CollectionChangedEvent', function(event, data) {
        if (data.added && (data.record.docid === $scope.record.docid)) {
            // The selected record got added to this collection.
            recordiseCollection(data.collection);
            addNode(data.collection);
        }

        updateLink(collectionPrefix + data.collection.id,
                   data.record.docid,
                   makeCollectionLink(data.collection, data.record),
                   data.added);
    });

    $scope.$on('RecordSelectedEvent', function(event, docid) {
        var record = $scope.nodeMap[docid];
        if (record) {
            setFocusRecord(record);
        }
    });

    $scope.$on('ViewLinkEvent', function(event, link) {
        let key = edgeKey(link);
        $scope.edge.classed('active', d => d.key == key);
        //console.log('ViewLinkEvent', link);
        //showLinkDialog(makeLink(link));
    });

    $scope.$on('HighlightLinkEvent', function(event, link, isHighlighted) {
        if (link.entityType === 'Collection') {
            link = makeCollectionLink(link, $scope.record);
        }
        let key = edgeKey(link);
        $scope.edge.classed('active', d => {
            let activated = isHighlighted && d.key === key;
            return activated;
        });
    });

    function initSingleRecord(docid) {
        $scope.record = { docid: docid, fixed : true };
        $scope.nodes = [ ];
        $scope.edges = [ ];
        $scope.nodeMap = { };
        $scope.edgeMap = [ ];
        $scope.status = 'loading';
        //TODO: use collectionPrefix
        var p;
        if (docid.match(/^HuNI\*\*\*Collection\*\*\*(\d+)$/)) {
            var match = docid.match(/\d+/);
            $scope.record.id = match[0];
            p = getCollectionInfo($scope.record);
        }
        else {
            p = getRecordInfo($scope.record);
        }
        p.finally(function() {
            // solr.augmentRecords doesn't propogate errors for missing
            // records, so just check that we got something here.
            $scope.status = _.has($scope.record, 'displayName')
                          ? 'ok' : 'empty';

            updateAddThisInfo();
        });
    }

    function setChain(recordsAndLinks) {
        if (!$scope.graph) {
            initGraph();
        }

        // We either have a path [ node, link, node, link, node ], or a
        // disjoint pair [ node, node ].
        // We don't actually use the link objects here, so filter them out.
        let hasLinks = recordsAndLinks.length > 2;
        let records = hasLinks
              ? _.filter(recordsAndLinks, x => _.has(x, 'docid'))
              : recordsAndLinks;
        let links = _.filter(recordsAndLinks, x => !_.has(x, 'docid'));

        let size = $scope.graph.size();
        let n = records.length + 1;
        let w = size[0];
        let y = size[1] / 2;

        // Add a little vertical zig-zag to the y positions so that the nodes
        // don't all appear in a straight horizontal line. This value gets
        // negated for each node.
        let yBump = 15;

        // Augment each record with the displayName property.
        // Add the nodes to the graph, and if necessary, the links as well.
        let prev = undefined;
        _.each(records, (record, index) => {
            record.initialPos = { x: (index + 1) * w / n, y: y + yBump };
            yBump = -yBump;

            let source = addNode(record);
            if (hasLinks && prev) {
                let link = links[index-1]; // link to previous node
                addEdge(prev, source, link);
            }
            prev = source;
        });

        // Populate the links for the first and last nodes.
        getRecordInfo($scope.record);
        getRecordInfo(prev);

        $scope.status = 'ok';
    }

    function updateAddThisInfo() {
        addthis_share.title = "HuNI Record View: " + $scope.record.displayName;
        addthis_share.passthrough.twitter.text = addthis_share.title + "\n" + addthis_share.description;
    }

    function initShortestPath(from_docid, to_docid) {
        $scope.record = { docid: from_docid, fixed : true };
        $scope.nodes = [ ];
        $scope.edges = [ ];
        $scope.nodeMap = { };
        $scope.edgeMap = [ ];
        $scope.status = 'loading';

        graphQuery.shortestPathQuery(from_docid, to_docid).then(result => {
            if (result.length == 0) {
                $scope.status = 'empty';
                return;
            }
            $scope.record = result[0];
            setChain(result);
        });
    }

    if ($routeParams.to_docid) {
        initShortestPath($routeParams.docid, $routeParams.to_docid);
    }
    else {
        initSingleRecord($routeParams.docid);
    }
}]);
