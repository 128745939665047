angular.module('huni').filter('pluck', function() {
    // Take an array of objects and a key, and return an array of the
    // corresponding values.
    return function(input, key) {
        if (! _.isArray(input)) {
            return input;
        }
        return _.pluck(input, key);
    };
});
