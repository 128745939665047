angular.module('huni').controller('MyHuniLinkController', [
    '$rootScope', '$scope', '$timeout',
    'HuniBackend', 'SolrSearchService', 'DialogService',
function ($rootScope, $scope, $timeout, huni, solr, dialog) {

    function setMessage(type, msg) {
        $scope.userAlert.type    = type;
        $scope.userAlert.message = msg;
    }
    function ok(msg)    { setMessage('success', msg); }
    function error(msg) { setMessage('danger',  msg); }

    function augmentLinks(links) {
        var from_docids = _.pluck(links, 'from_docid');
        var to_docids   = _.pluck(links, 'to_docid');
        var docids      = _.uniq(from_docids.concat(to_docids));

        return solr.getRecords(docids)
            .then(function(records) {
                var record = _.indexBy(records, 'docid');
                _.each(links, function(link) {
                    link.from = record[link.from_docid];
                    link.to   = record[link.to_docid];
                });

                $scope.links = links;
                $scope.status = links.length === 0 ? 'empty' : 'ok';
        });
    }

    function fetchLinks() {
        $scope.status = 'loading';
        huni.get('link')
            .success(augmentLinks)
            .error(function() { $scope.status = 'error'; });
    }

    $rootScope.$on('LinkChangedEvent', fetchLinks);

    $scope.editLink = function(link) {
        $scope.$emit('EditLinkEvent', link);
    };

    $scope.deleteLink = function(link) {
        var linkName = link.from.displayName + ' '
                     + link.linktype + ' '
                     + link.to.displayName;
        dialog.confirmationDialog('Really delete "' + linkName + '"?')
            .then(function() {
                huni.delete('link/' + link.id)
                    .success(function() {
                        var index = $scope.links.indexOf(link);
                        $scope.links.splice(index, 1);
                        ok('Deleted "' + linkName + '"');
                    })
                    .error(function() {
                        error('There was an error deleting"' + linkName + '"');
                    });
            });
    };

    function init() {
        $scope.links = undefined;
        $scope.userAlert = { };
        fetchLinks();
    }

    init();
}]);
