angular.module('huni').factory('ReachableNodesSearch', [
'$filter', '$log', '$q', 'DelayedCallService', 'FacetSetService', 'GraphQueryService', 'SolrSearchService',
function($filter, $log, $q, delayedCall, facetSetService, graphQuery, solrQuery) {

    function newSearch(queryParams, options) {
        let facetSet = facetSetService.create(queryParams);
        let docid = queryParams.docid;

        const recordsPerPage = 10;
        const searchDelayMillis = 1200;
        let search = {
            title: "Graph Search Results",
            hasSourceRecord:    true,
            hasUserString:      true,
            userString:         queryParams.q || '',
            currentPage:        queryParams.p || 1,
            // if queryParams.p > 1, then we need to set totalRecords to match,
            // otherwise the pagination controller freaks out and resets the
            // page to 1
            totalRecords:       queryParams.p * recordsPerPage || 1,
            recordsPerPage:     recordsPerPage,
            records:            [],                     // this pages worth
            facet:              facetSet,
            status: '',             // user-facing search progress status
        };

        function updateFacetSelections(facetNameToSkip) {
            search.currentPage = 1;
            doSearch('delayed', function() { return {
                records: recordSearch(),
                facets:  facetSearch(facetNameToSkip),
            }});
        }

        function updateUserString() {
            search.currentPage = 1;
            search.totalRecords = 0;
            doSearch('immediate', function() { return {
                records: recordSearch(),
                facets:  facetSearch(),
            }});
        };

        function makeFilter(facetNameToSkip) {
            let selections = facetSet.getSelectedFieldNames();
            return _.omit(selections, facetNameToSkip);
        };

        function recordSearch() {
            let filter = makeFilter();
            let page = search.currentPage - 1;
            let rows = recordsPerPage;
            let str = search.userString;

            return graphQuery.reachableNodeSearch(docid, filter, page, rows, str);
        }

        function facetSearch(facetNameToSkip) {
            let str = search.userString;
            let promises = { };
            let facetNames = _.without(facetSet.facetNames, facetNameToSkip);
            _.each(facetNames, facetName => {
                let filter = makeFilter(facetName);
                promises[facetName] =
                    graphQuery.reachableNodeFacet(docid, filter, facetName, str);
            });
            return $q.all(promises);
        }

        function sourceSearch() {
            //console.log("Searching for", options);
            return solrQuery.getRecords([ docid ])
                            .then(result => {
                                if (result.length > 0) {
                                    return result[0];
                                }
                                return undefined;
                             });
        }

        function getUrlQueryParams() {
            var newQuery = facetSet.getUrlQueryParams();
            newQuery.docid = docid;
            if (search.currentPage > 1) {
                newQuery.p = search.currentPage;
            }
            let q = search.userString.trim();
            if (q.length > 0) {
                newQuery.q = q;
            }
            return newQuery;
        }

        function gotoPage(page) {
            search.currentPage = page;
            doSearch('immediate', function() { return {
                records: recordSearch(),
            }});
        }

        function finishSearchRaw(promisesFunc) {
            search.isBusy = true;
            return $q.all(promisesFunc()).then(
                result => {
                    if (_.has(result, 'records')) {
                        search.records = result.records;
                    }

                    if (_.has(result, 'facets')) {
                        _.each(result.facets, (histogram, facetName) => {
                            facetSet.setCounts(facetName, histogram);
                        })
                    }

                    if (_.has(result, 'source')) {
                        search.source = result.source;
                    }

                    search.totalRecords = facetSet.getTotalCount();

                    if (search.totalRecords > 0) {
                        let total = search.totalRecords;
                        let first = (search.currentPage - 1)
                                        * search.recordsPerPage + 1;
                        let last = first + search.recordsPerPage - 1;
                        if (last > total) {
                            last = total;
                        }
                        let number = $filter('number');
                        search.status = number(first) + ' - ' + number(last)
                                      + ' of ' + number(total);
                        search.loadingStatus = 'ok';
                    }
                    else {
                        search.status = 'No records found';
                        search.loadingStatus = 'empty';
                    }
                },
                failure => {
                    search.status = 'Search failed. Try again later';
                    search.loadingStatus = 'error';
                },
            ).finally(result => search.isBusy = false);
        }

        let finishSearch = delayedCall('ReachableNodesSearch',
                                       finishSearchRaw,
                                       searchDelayMillis);

        // when is either 'delayed' or 'immediate'
        function doSearch(when, promisesFunc) {
            search.records = [ ];
            search.status = 'Searching';
            search.loadingStatus = 'loading';

            finishSearch[when](promisesFunc);
        }

        _.extend(search, {
            getUrlQueryParams,
            gotoPage,
            updateFacetSelections,
            updateUserString,
        });

        if (options.source) {
            search.source = options.source;
        }

        doSearch('immediate', function() { return {
            records: recordSearch(),
            facets:  facetSearch(),
            source:  options.source || sourceSearch(),
        }});

        return search;
    }

    return {
        newSearch:    newSearch,
    };
}]);
