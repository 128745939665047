angular.module('huni').directive('exportCollectionButton',
['$location', '$timeout', 'DialogService', 'HuniBackend', 'SolrSearchService',
function($location, $timeout, dialog, huni, solr) {

    function getLocationRoot() {
        let absUrl = $location.absUrl();  // http://host/root/#/a/b?q=3
        let url    = $location.url();     //                   /a/b?q=3
        let index = absUrl.indexOf(url);
        if (index < 0) {
            return absUrl;
        }
        return absUrl.substring(0, index);
    }

    function dateString(s) {
        return moment.utc(s).local().format('LLL');
    }

    function mergeRows(metadata, rows) {
        let rowsTransposed = _.zip.apply(_, rows);
        rowsTransposed.unshift(metadata);
        let mergedRows = _.zip.apply(_, rowsTransposed);
        return mergedRows;
    }

    function link(scope, element, attrs) {
        scope.formName = 'exportCollectionForm' + scope.$id;
        scope.exportData = {
            filename: 'collection.csv',
            columns: [
              'Collection Description',
              'Record Name',
              'HuNI Record',
              'Original Record',
            ],
            rows: [ ],
        };

        function doExportCollection() {
            let c = scope.collection;
            let baseUri = getLocationRoot() + '/record/';
            let form = document.forms[scope.formName];
            let date = moment().format('YYYYMMDD-hhmmss');
            huni.get('publication/' + c.id)
                .then(function(resp) {
                    return _.pluck(resp.data.records, 'docid');
                })
                .then(solr.getRecords)
                .then(function(records) {
                    // We now have all the data we need for the export.
                    // Fill in the data in the exportCollection form, and
                    // then trigger the form submission.
                    scope.exportData.filename =
                        'huni-export-' + date + '.csv';

                    let dataRows = _.map(records, function(r) {
                        return [
                            r.displayName,
                            baseUri + r.docid,
                            r.sourceRecordLink || '',
                        ];
                    });
                    let collectionPath = c.is_public
                        ? '/collection/'
                        : '/myhuni/collection/'
                    let metadata = [
                        'Collection name: ' + c.name || '',
                        'Created by: '      + c.username || '',
                        'Description: '     + (c.synopsis || '(none)'),
                        'Created: '         + dateString(c.created_utc),
                        'Last modified: '   + dateString(c.modified_utc),
                        'Collection URL: '  + getLocationRoot()
                                            + collectionPath + c.id,
                    ];

                    scope.exportData.rows = mergeRows(metadata, dataRows);

                    // We've set the scope.exportData variables, but the
                    // bindings in the exportCollection form have not yet
                    // been updated. We cannot call scope.$update() as we
                    // are already in an update. Instead we use $timeout
                    // with a zero delay - it will fire once the $update is
                    // complete (by then the bindings will be up-to-date).
                    $timeout(function() { form.submit(); });
              })
              .catch(function() {
                  error('There was an error exporting collection "' + c.name + '"');
              });
        }

        function exportCollection() {
            dialog.licenceDialog().then(doExportCollection);
        }

        // This stops you doing multiple exports accidentally
        scope.exportCollection = _.debounce(exportCollection, 300);
    }

    return {
        restrict: 'E',
        scope: {
            collection: '=',
        },
        templateUrl: 'partials/buttons/export-collection.html',
        link: link,
    };
}
]);
