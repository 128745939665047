angular.module('huni').controller('ChooseCollectionDialogController', [
    '$scope', '$modalInstance', 'HuniBackend',
function ($scope, $modalInstance, huniBackend) {

    $scope.init = function() {
        $scope.loading = true;
        $scope.error   = '';

        huniBackend.get('collection')
            .success(function(collections) {
                $scope.collections = collections;
                $scope.tab = {
                    choose_existing: {
                        active:     collections.length > 0,
                        disabled:   collections.length === 0,
                    },
                    create_new: {
                        active:     collections.length === 0,
                        disabled:   false,
                    },
                };
                $scope.data = {
                    newCollection: "",
                    selectedCollection:
                        (_.size(collections) > 0) ? collections[0]
                                                  : undefined,
                };
            })
            .error(function() {
                $scope.error = 'There was an error loading your collections';
            })
            .finally(function() {
                $scope.loading = false;
            });
    };

    // The user hit the choose-existing button
    var select_existing = function() {
        //console.log("Selected ", $scope.selectedCollection);
        $modalInstance.close($scope.data.selectedCollection);
    };

    // The user hit the create-new button
    var create_new = function() {
        var name = $scope.data.newCollection.trim();
        huniBackend.post('collection', { name: name })
            .success(function(response) {
                $modalInstance.close(response);
            })
            .error(function() {
                $scope.error = 'There as an error saving your collection';
            });
    };

    $scope.ok = function() {
        if ($scope.tab.choose_existing.active) {
            select_existing();
        }
        else if ($scope.tab.create_new.active) {
            create_new();
        }
        else {
            //console.log("Neither tab is active?");
            $modalInstance.dismiss("Internal error");
        }
    };

    $scope.init();
}]);
