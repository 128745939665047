angular.module('huni').filter('recordName', function() {
    // Takes a record as input and returns a title for that record
    // TODO: do this as a post-process harvest step
    return function(input) {
        if (!input) {
            return '';
        }

        return input.displayName;
    };
});
