angular.module('huni').factory('DialogService', ['$modal',
function($modal) {

    // This does all the work of setting up a basic dialog.
    function showDialog(templateUrl, scopeParams, modalParams = { }) {
        modalParams.templateUrl = templateUrl;
        modalParams.controller = ['$scope', '$modalInstance',
            ($scope, $modalInstance) => { _.extend($scope, scopeParams) }];
        return $modal.open(modalParams).result;
    }

    function confirmationDialog(message) {
        return showDialog('partials/dialogs/confirmation.html', { message });
    }

    function linkDialog(link) {
        return showDialog('partials/dialogs/link-view.html', { link });
    }

    function licenceDialog() {
        return showDialog('partials/dialogs/licence.html');
    }

    return {
        confirmationDialog,
        licenceDialog,
        linkDialog,
    };
}]);
