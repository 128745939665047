angular.module('huni').directive('linkButton',
function() {

    function link(scope, element, attrs) {

        function linkAlreadyExists() {
            var snap = scope.snapIn;
            return _.find(scope.record.links, function(link) {
                return (snap.link.user_id === link.user_id)
                    && (snap.fromRecord.docid === link.to_docid)
                    && (snap.rw.selectedRHS.id === link.pairtype_id);
            });
        }

        function refreshState() {
            if (!scope.snapIn) {
                // No snapin active
                scope.isEnabled = true;
                scope.canComplete = false;
            }
            else if (scope.snapIn.name !== 'link') {
                // Some other snapin is active
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else if (scope.snapIn.fromRecord.docid === scope.record.docid) {
                // This record is selected as the 'from' record
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else if (!scope.snapIn.toRecord) {
                // The second record has not been selected.
                scope.isEnabled = true;
                scope.canComplete = true;
            }
            else if (scope.snapIn.toRecord.docid === scope.record.docid) {
                // This record is selected as the second 'from' record
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else if (scope.snapIn.form.$dirty) {
                // Both records are selected in the snapin, but it hasn't
                // been saved.
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else if (linkAlreadyExists()) {
                // There already exists a link between the source record
                // and this record using the selected relationship.
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else {
                scope.isEnabled = true;
                scope.canComplete = true;
            }
        }

        scope.$on('LinkSnapInChangedEvent', refreshState);
        scope.$watch('snapIn', refreshState);
        scope.$watch('record', refreshState);
        scope.$watch('record.links', refreshState);
        refreshState();
    }

    return {
        restrict: 'E',
        scope: {
            record: '=',
            snapIn: '=',
        },
        templateUrl: 'partials/buttons/link.html',
        link: link,
    };
}
);
