angular.module('huni').directive('myHuniPills', [
    '$timeout', 'MyHuniMenuItems',
function($timeout, items) {

    function link(scope) {
        var alertLevel = 0;
        var alertTimeoutMillis = 5000;

        scope.items = items;
        scope.alertTypes = [ 'success', 'warning', 'danger', 'info' ];
        scope.displayMessage = '';

        scope.isActive = function(tabName) {
            return (tabName === scope.activeTab) ? "active" : "";
        };

        scope.$watch('userAlert.message', function(newValue, oldValue) {
            if (_.isUndefined(newValue)) {
                // We got here because we set alertMessage to undefined
                // ourselves. Ignore this case.
                return;
            }
            //
            // Immediately clear the incoming message to that we detect when
            // the client sets it again, even if it is the same message.
            scope.userAlert.message = undefined;

            // Set the message which is actually displayed to the incoming
            // message.
            scope.displayMessage = newValue;

            alertLevel += 1;
            $timeout(function() {
                alertLevel -= 1;
                if (alertLevel === 0) {
                    scope.displayMessage = undefined;
                }
            }, alertTimeoutMillis);
        });

        scope.closeAlert = function() {
            scope.displayMessage = '';
        };

    }

    return {
        restrict: 'E',
        link: link,
        scope: {
            activeTab: '@',
            userAlert: '=',
        },
        templateUrl: 'partials/directives/myhuni-pills.html'
    };
}]);
