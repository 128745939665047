angular.module('huni').directive('recordMini',
function() {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            record: '=',
        },
        templateUrl: 'partials/directives/record-mini.html',
    };
});
