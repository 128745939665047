angular.module('huni').filter('recordClass', function() {
    // Takes a record as input and returns an icon for that record
    return function(input) {
        if (!input) {
            return '';
        }

        var entityType = input.entityType;

        if (!entityType) {
            // Oh crap what's going on here?
            // I think I know. This filter gets executed twice. Once at page
            // load, and once after the results come in. This case is for
            // the initial load.
            entityType = '';
        }

        // Hack - substitute fake collection icon for huni collections.
        if (input.sourceAgencyCode === 'HuNI') {
            entityType = "collection";
        }
        return entityType.toLowerCase();
    };
});
