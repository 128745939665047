angular.module('huni').directive('collectionSnapin', [
    '$rootScope', '$modal', 'CollectionRole', 'HuniBackend',
function($rootScope, $modal, collectionRole, huniBackend) {

    function updateCollection(dest, src, fields) {
        _.each(fields, field => {
            dest[field] = src[field];
        });
    }

    function updateRecord(collection, record, isAdded) {
        return {
            apply: function() {
                var method = isAdded ?
                    huniBackend.put : huniBackend.delete;
                var uri = 'collection/' + collection.id
                                    + '/' + record.docid;
                return method(uri).success(function(result) {
                    collection.record_count = result.record_count;
                    $rootScope.$broadcast('CollectionChangedEvent', {
                            collection: collection,
                            record:     record,
                            added:      isAdded,
                        });
                });
            },
            undo: function() {
                return updateRecord(collection, record, !isAdded);
            },
            message: function(isOk) {
                var verb, ret;
                if (isOk) {
                    verb = isAdded ? 'Added' : 'Removed';
                    ret = verb + ' ' + record.displayName;
                }
                else {
                    verb = isAdded ? 'add' : 'remove';
                    ret = 'Failed to ' + verb + ' ' + record.displayName;
                }
                return ret;
            },
        };
    }

    function updateIsPublic(collection, is_public) {
        return {
            apply: function() {
                var method = huniBackend.put;
                var uri = 'collection/' + collection.id;
                return method(uri, { is_public: is_public })
                        .success(function(result) {
                            updateCollection(collection, result, [
                                'is_public',
                                'modified_utc',
                            ]);
                            $rootScope.$broadcast('CollectionDetailsEvent',
                                collection
                            );
                        })
                        .error(function(result) {
                            // Reset the is_public checkbox
                            collection.is_public = !is_public;
                        });
            },
            undo: function() {
                return updateIsPublic(collection, !is_public);
            },
            message: function(isOk) {
                var audience = is_public ? 'everyone' : 'you only';
                return isOk ? 'Collection now visible to ' + audience
                            : 'Collection update failed';
            },
        };
    }

    function updateDetails(collection, newDetails) {
        var oldDetails = _.pick(collection, _.keys(newDetails));
        return {
            apply: function() {
                var method = huniBackend.put;
                var uri = 'collection/' + collection.id;
                return method(uri, newDetails)
                        .success(function(result) {
                            updateCollection(collection, result, [
                                'name',
                                'synopsis',
                                'modified_utc',
                            ]);
                            $rootScope.$broadcast('CollectionDetailsEvent',
                                collection
                            );
                        });
            },
            undo: function() {
                return updateDetails(collection, oldDetails);
            },
            message: function(isOk) {
                return isOk ? 'Collection updated'
                            : 'Collection update failed';
            },
        };
    }

    function link(scope, element, attrs) {

        scope.name = 'collection';
        scope.isActive = false;
        scope.selectedCollection = undefined;
        scope.message = undefined;

        function launchDialog() {
            var modalInstance = $modal.open({
                templateUrl: 'partials/dialogs/choose-collection.html',
                controller: 'ChooseCollectionDialogController',
                resolve: {
                    huniBackend: function() { return huniBackend; },
                },
            });

            return modalInstance.result;
        }

        function doUpdate(update) {
            update.apply()
                .success(function(result) {
                    scope.ok      = true;
                    scope.message = update.message(true);
                    scope.undo    = update.undo();
                })
                .error(function(result) {
                    scope.ok      = false;
                    scope.message = update.message(false);
                    scope.undo    = update;
                });
        }

        function setActive(isActive) {
            if (scope.isActive !== isActive) {
                scope.isActive = isActive;
                $rootScope.$broadcast('SnapInStateChangedEvent', scope);
            }
        }

        function onRecordChange(ev, record, isAdd) {
            if (scope.selectedCollection) {
                var update = updateRecord(scope.selectedCollection,
                                            record, isAdd);
                doUpdate(update);
            }
            else {
                launchDialog()
                    .then(function(collection) {
                        setSelectedCollection(collection);
                        setActive(true);
                        onRecordChange(ev, record, isAdd);
                    });
            }
        }

        function setSelectedCollection(collection) {
            scope.selectedCollection = collection;
        }

        scope.permissions = function() {
            return scope.selectedCollection
                ? collectionRole.permissions(scope.selectedCollection.role_id)
                : { };
        }

        scope.editDetails = function() {
            scope.message = undefined;
            var c = scope.selectedCollection;
            var p = scope.permissions();
            var modalInstance = $modal.open({
                templateUrl: 'partials/dialogs/collection-details.html',
                controller: 'CollectionDetailsDialogController',
                resolve: {
                    collection:  function() { return c; },
                    permissions: function() { return p; },
                },
            });

            modalInstance.result.then(function(results) {
                var update = updateDetails(c, results);
                doUpdate(update);
            });
        };

        scope.closeAlert = function() {
            scope.message = undefined;
        };

        scope.close = function() {
            scope.selectedCollection = undefined;
            scope.last = { };
            scope.showAlert = false;
            setActive(false);
        };

        scope.undoCollectionOperation = function() {
            doUpdate(scope.undo);
        };

        scope.updateIsPublic = function(value) {
            var c = scope.selectedCollection;
            var update = updateIsPublic(c, c.is_public);
            doUpdate(update);
        };

        $rootScope.$on('CollectionRecordChangeEvent', onRecordChange);
        $rootScope.$on('EditLinkEvent', scope.close);
        $rootScope.$on('EditCollectionEvent', function(ev, collection) {
            setSelectedCollection(collection);
            setActive(true);
        });
    }

    return {
        restrict: 'E',
        scope: {
        },
        templateUrl: 'partials/snapins/collection.html',
        link: link,
    };
}]);
