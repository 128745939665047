angular.module('huni').factory('GraphQueryService', [
'$http', '$log', '$q', 'HuniBackend',
function($http, $log, $q, backend) {

  // This limits the path length on reachableNode queries. Beyond this the
  // runtime explodes, and the actual results start to grow very slowly.
  let maxHops = 5;

  function shortestPathQuery(from_docid, to_docid) {
      // If we find a path, return that, otherwise, returns just the
      // endpoints as an unconnected pair. A path will have at least
      // three components [node, edge, node], so a two-element list will
      // always be distinct.
      let statement = `
          MATCH (lhs:Entity { docid: {from_docid} }),
                (rhs:Entity { docid: {to_docid}   })
          WITH lhs, rhs
          OPTIONAL MATCH p=shortestPath((lhs)-[:Link*]-(rhs))
          RETURN coalesce(p, [ lhs, rhs ])
      `;

      let parameters = { from_docid, to_docid };
      return doQuery(statement, parameters).then(result => {
          if (result.data.length == 0) {
              // We didn't even match the entities.
              return [ ];
          }
          return result.data[0].row[0];
      }).then(decorateLinks);
  }

  function decorateLinks(recordsAndLinks) {
      let links = _.filter(recordsAndLinks, x => _.has(x, 'linktype_id'));
      let user_ids = _.uniq(_.pluck(links, 'user_id'));

      if (user_ids.length == 0) {
          return recordsAndLinks;
      }

      // This gets called whether the user lookup works or not. As a fallback
      // we use 'User $id' as the username.
      function updateLinks(profileMap) {
          _.each(links, link => {
              if (!link.created_utc && link.created) {
                  link.created_utc = new Date(link.created);
              }
              if (!link.modified_utc && link.modified) {
                  link.modified_utc = new Date(link.modified);
              }
              if (!link.username && link.user_id) {
                  let user = profileMap[link.user_id];
                  link.username = user ? user.username : `User ${link.user_id}`;
              }
          });
      }

      return backend.get('/profile/find', { u: user_ids })
          .then(response => {
              let profileMap = _.indexBy(response.data, 'user_id');
              updateLinks(profileMap);
              return recordsAndLinks;
          })
          .catch(error => {
              updateLinks({ });
              return recordsAndLinks;
          });
  }

  function makeStringMatchConditions(item, stringMatch) {
      let escaped = stringMatch.trim().toLowerCase().replace(/'/g, "\\'");
      if (escaped.length == 0) {
          return [ ];
      }
      return _.map(escaped.split(/\s+/), word =>
          `${item}.searchText CONTAINS '${word}'`
      );
  }

  function makeFacetMatchConditions(item, facet) {
      return _.map(_.keys(facet), key => `${item}.${key} IN {filter}.${key}`);
  }

  function reachableNodeFacet(from_docid, filter, field, stringMatch = '') {
      let conditions = _.flatten([
            makeFacetMatchConditions('rhs', filter),
            makeStringMatchConditions('rhs', stringMatch),
      ]);
      let where = ['lhs <> rhs', ...conditions].join(' AND ');

      // maxHops and field are being interpolated in the statement, cypher does
      // not allow these to be parameters
      let statement = `
          MATCH (lhs:Entity {docid: {from_docid}})
          WITH lhs
          MATCH (rhs:Entity)
          WHERE ${where}
          WITH lhs, rhs
          MATCH (lhs)-[:Link*..${maxHops}]-(rhs)
          WITH distinct(rhs) as nodes
          WITH distinct(nodes.${field}) as name,
               count(nodes.${field}) as count
          RETURN name, count
      `;

      let parameters = { from_docid, filter };
      //$log.log(statement, parameters);
      return doQuery(statement, parameters).then(result => {
          // Create a hash from an array of [ key, value ] pairs.
          let hist = _.object(_.map(result.data, item => item.row));
          return hist;
      });
  }

  function reachableNodeSearch(from_docid, filter, pageNumber, pageSize, stringMatch = '') {
      let conditions = _.flatten([
            makeFacetMatchConditions('rhs', filter),
            makeStringMatchConditions('rhs', stringMatch),
      ]);
      let where = ['lhs <> rhs', ...conditions].join(' AND ');

      // Note we are interpolating maxHops in the statement - cypher does not
      // allow this to be a parameter.
      let statement = `
          MATCH (lhs:Entity { docid: {from_docid} }),
                (rhs:Entity),
                path=((lhs)-[:Link *..${maxHops}]-(rhs))
          WHERE ${where}
          WITH distinct(rhs) as record, length(path) as pathLengths
          ORDER BY pathLengths, record.docid
          RETURN record, collect(pathLengths)[0] as pathLength
          ORDER BY pathLength, record.docid
          SKIP {skip}
          LIMIT {limit}
      `;

      let parameters = {
          from_docid:   from_docid,
          skip:         pageNumber*pageSize,
          limit:        pageSize,
          filter:       filter,
      };

      //$log.log(statement, parameters);
      return doQuery(statement, parameters).then(result => {
          return _.map(result.data, item => {
              let [ record, pathLength ] = item.row;
              record.baconDistance = pathLength;
              record.bacon_docid = from_docid;
              return record;
          });
      });
  }

  function doQuery(statement, parameters = { }) {
      let path = '/neo'
      let data = { statements: [{
          statement:  statement,
          parameters: parameters,
      }] };
      return $http.post(path, data).then(
          result => {
              let data = result.data;
              if (data.errors.length > 0) {
                  _.each(data.errors, error => {
                      $log.error(`Cypher error: ${error.code}`);
                      _.each(error.message.split("\n"), message => {
                          $log.error(message);
                      });

                  });
                  return $q.reject('cypher query failed');
              }

              let results = data.results;
              if (results.length != 1) {
                  $log.error(`expecting 1 result, not ${results.length}`);
                  return $q.reject(`expecting 1 result, not ${results.length}`);
              }

              return result.data.results[0];
          },
      );
  }

  return {
      reachableNodeFacet,
      reachableNodeSearch,
      shortestPathQuery,
  };

}]);
