angular.module('huni').filter('matchLookahead', function() {

/*
    This filter is used as a typeahead filter.

    It takes a userString and a suggestionArray, and returns a filtered
    version of suggestionArray, including only those that 'match' the
    suggestion.

    The old way was to do an exact substring match.

    This approach is slightly different. Rather than matching the whole
    user string, it is instead split into words (userWords).

    If any of those words match, we accept the suggestion.

    eg. If the user string is:  'was marr'

    Then these suggestions are all accepted:

        'is married'
        'was born'
        'never married'

    The idea is somewhat similar to stopword filtering. The intention is
    that by typing 'is married' the user doesn't miss out on 'married to'.
*/

    function isSubstring(needle, haystack) {
        return haystack.indexOf(needle) >= 0;
    }

    return function(suggestionArray, userString) {
        var userWords = userString.trim().toLowerCase().split(/ +/);
        function isCandidate(suggestion) {
            var lcSuggestion = suggestion.toLowerCase();
            return _.find(userWords, function(userWord) {
                return isSubstring(userWord, lcSuggestion);
            });
        }
        return _.filter(suggestionArray, function(suggestion) {
            return isCandidate(suggestion);
        });
    };
});
