angular.module('huni').service('SettingsService', [
    '$cookieStore',
function($cookieStore) {

    var self = this;

    var cookieName = 'huni-settings';

    self.get = function(group, key) {
        var settings = $cookieStore.get(cookieName);
        if (settings) {
            var groupSettings = settings[group];
            if (groupSettings) {
                return groupSettings[key];
            }
        }
        return;
    };

    self.set = function(group, key, value) {
        var settings = $cookieStore.get(cookieName) || {};
        var groupSettings = settings[group];
        if (!groupSettings) {
            settings[group] = groupSettings = {};
        }
        groupSettings[key] = value;
        $cookieStore.put(cookieName, settings);
    };

    self.remove = function(group, key) {
        var settings = $cookieStore.get(cookieName);
        if (settings) {
            var groupSettings = settings[group];
            if (groupSettings && _.has(groupSettings, key)) {
                delete groupSettings[key];
                $cookieStore.put(cookieName, settings);
            }
        }
    };
}]);
