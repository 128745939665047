angular.module('huni').factory('HuniBackend', [
    '$rootScope', '$http', 'cfg',
function($rootScope, $http, cfg) {

    function uri(path) {
        if (path.substr(0, 1) != "/") {
            path = "/" + path;
        }
        return cfg.url + path;
    }

    function get(path, params) {
        return $http.get(uri(path), { params: params });
    };

    function put(path, data) {
        return $http.put(uri(path), data);
    };

    function post(path, data) {
        return $http.post(uri(path), data);
    };

    function deleteIsAReservedWord(path) {
        return $http.delete(uri(path));
    };

    return {
        delete: deleteIsAReservedWord,
        get,
        post,
        put,
        uri,
    };
}]);
