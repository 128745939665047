angular.module('huni').filter('join', function() {
    // Take an array and a separator, and return the array elements joined by
    // separator.
    return function(input, sep) {
        if (! _.isArray(input)) {
            return input;
        }
        if (_.isUndefined(sep)) {
            sep = ', ';
        }
        return input.join(sep);
    };
});
