angular.module('huni').controller('ResultsController',
    [ '$scope', '$routeParams', '$location', '$anchorScroll', '$analytics',
      'ReachableNodesSearch', 'SolrTextSearch',
function ($scope, $routeParams, $location, $anchorScroll, $analytics, graph, solr) {

    function newSearch() {
        let queryParams = $location.search();
        let docid = queryParams.docid;
        let options = { };
        if (docid && $scope.search) {
            // If we already have this record loaded, reuse it.
            let source = _.find($scope.search.records, r => (r.docid == docid));
            if (source) {
                options.source = source;
            }
        }
        let searcher = docid ? graph : solr;
        return searcher.newSearch(queryParams, options);
    }

    function init(docid) {
        $scope.search = newSearch();
    }

    function updateQueryParams() {
        $location.search($scope.search.getUrlQueryParams());
    }

    $scope.updateUserString = function() {
        $scope.search.updateUserString();
        updateQueryParams();
    };

    $scope.gotoPage = function(page) {
        $scope.search.gotoPage(page);
        updateQueryParams();
        $anchorScroll(); // back to the top!
    };

    $scope.updateFacetSelections = function(facetToSkip) {
        $scope.search.updateFacetSelections(facetToSkip);
        updateQueryParams();
    };

    $scope.topOfPage = $anchorScroll;

    $scope.$on("$routeUpdate", function(event, data) {
        // We've detected a route change - if we did it ourselves, then
        // the params will match. If they differ, this was done by the user,
        // so update the search.
        if (!_.isEqual($location.search(), $scope.search.getUrlQueryParams())) {
            $scope.search = newSearch();
        }
        else {
            $analytics.pageTrack($location.url());
        }
    });

    init();
}]);
