angular.module('huni').directive('huniAlert', function () {
    return {
        restrict:'EA',
        templateUrl:'partials/directives/huni-alert.html',
        transclude:true,
        replace:true,
        scope: {
            type: '=',
            close: '&'
        },
    };
});
