angular.module('huni').factory('SolrSearchService', [
'$http', '$log', '$q', 'cfg',
function($http, $log, $q, cfg) {

    function makeDisplayName(input) {
        if (input.displayName) {
            return input.displayName;
        }

        if (input.isDeleted) {
            return '[Deleted Record]';
        }

        // This record will have one of these:
        // * name only
        // * individualName and/or familyName
        // * none of the above
        // There's no overlap.
        return _.chain(['name', 'individualName', 'familyName'])
                .map(field => input[field])
                .compact()
                .value()
                .join(' ') || '[untitled]';
    }

    function formatDate(dateStr) {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

        var d = new Date(dateStr);
        var day = d.getUTCDate();
        var month = monthNames[(d.getUTCMonth())];
        var year = d.getUTCFullYear();

        return [ day, month, year ].join(' ');
    }

    function parseRecordDates(record) {
        const dateKeys = ['birthDate', 'deathDate', 'creationDate', 'endDate', 'releaseDate', 'startDate'];
        dateKeys.forEach(function (key) {
            if ( record[key] ) record[key] = formatDate(record[key]);
        });

        return record;
    }

    function doQuery(query) {
        return $http({
            method: 'POST',
            url: cfg.solr + '/select',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: query + '&wt=json',
        });
    }

    function doRecordsQuery(query) {
        return doQuery(query).then(function(resp) {
            _.each(resp.data.response.docs, record => {
                record.displayName = makeDisplayName(record);
                parseRecordDates(record);
            });

            return resp.data.response;
        });
    }

    // We can get lots of recordIds here. Break them into chunks and do one
    // call on each chunk.
    function getRecords(recordIds) {
        const chunkSize = 1000;
        let queryPromises = [ ];
        while (recordIds.length > 0) {
            let chunk = recordIds.splice(0, chunkSize);
            queryPromises.push(getRecordsChunk(chunk));
        }

        // Wait for all queries to complete, then flatten the list of lists
        // into a single list.
        return $q.all(queryPromises).then(recordLists =>
            _.flatten(recordLists, true)
        );
    };

    // This looks up a single chunk of records.
    function getRecordsChunk(recordIds) {
        let query = 'rows=' + recordIds.length + '&q=' +
            _.chain(recordIds)
             .map(function(id) {
                // The stars in the id need to be escaped, ftw!
                return "docid:" + id.replace(/\*/g, '\\*'); })
             .join(' OR ')
             .value();
        return doRecordsQuery(query).then(function(result) {
            return result.docs;
        });
    }

    /*
     * Given a list of partial records containing docid fields, lookup those
     * docids in solr, and merge the solr records with the partial records
     */
    function augmentRecords(dbRecords) {
        return getRecords(_.pluck(dbRecords, 'docid')).then(solrRecords => {
            let solrRecordMap = _.indexBy(solrRecords, 'docid');
            _.each(dbRecords, dbRecord => {
                let solrRecord = solrRecordMap[dbRecord.docid];
                _.extend(dbRecord, solrRecord);
            });
            return dbRecords;
        });
    };

    return {
        augmentRecords,
        doQuery,
        doRecordsQuery,
        getRecords,
    };
}]);
