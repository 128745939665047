angular.module('huni').directive('recordList', [
    'HuniBackend',
function(huniBackend) {

    function link(scope) {

        function getInfoForRecords(records) {
            // Split the docids into actual records and collections.
            var prefix = 'HuNI***Collection***';
            var part = _.chain(records)
                        .pluck('docid')
                        .partition(function(id) {
                            return id.indexOf(prefix) == 0;
                        })
                        .value();
            var collection_ids = _.map(part[0], function(id) {
                return id.substr(prefix.length);
            });
            var record_ids = part[1];

            if (record_ids.length > 0) {
                huniBackend.get('record/collection', { d: record_ids })
                    .success(function(collections) {
                        _.each(records, function(r) {
                            var c = collections[r.docid];
                            r.collections = _.indexBy(c, 'id');
                        });
                    });
                huniBackend.get('record/link', { d: record_ids })
                    .success(function(linkCount) {
                        _.each(records, function(r) {
                            r.linkCount = linkCount[r.docid] || 0;
                        });
                    });
            }
            if (collection_ids.length > 0) {
                huniBackend.get('publication/count', { c: collection_ids })
                    .success(function(collections) {
                        _.each(records, function(r) {
                            var id = r.docid.substr(prefix.length);
                            var c = collections[id];
                            if (c) {
                                _.extend(r, c);
                            }
                        });
                    });
            }
        }

        scope.$watch('records', function(newValue, oldValue) {
            // whoops!
            var records = newValue;
            if (!_.isEmpty(records)) {
                getInfoForRecords(records);
            }
        });
    }

    return {
        restrict: 'E',
        scope: {
            records:    '=',
            snapIn:     '=',
            user:       '=',
        },
        templateUrl: 'partials/directives/record-list.html',
        link: link,
    };
}]);
