angular.module('huni').controller('CollectionController', [
    '$rootScope', '$scope', '$location', '$routeParams',
    'HuniBackend', 'SolrSearchService',
function CollectionController(
    $rootScope, $scope, $location, $routeParams, huniBackend, solr) {

    function pathHas(str) {
        return $location.path().indexOf(str) >= 0;
    }

    function getCollection(id) {
        var endPoint = $scope.isPublic ? 'publication'
                                       : 'collection';
        $scope.status = 'loading';
        huniBackend.get(endPoint + '/' + id)
            .success(function(result) {
                $rootScope.title = result.name + ' - '
                  + ($scope.isPublic ? '' : 'My ')
                  + 'Collection';
                solr.augmentRecords(result.records)
                    .then(function(merged) {
                        result.records = merged;
                        $scope.collection = result;
                        if (pathHas('/manage')) {
                            $scope.$emit('EditCollectionEvent', result);
                        }
                        if ($scope.isPublic) {
                            updateAddThisDetails();
                        }
                        $scope.status = 'ok';
                    });
            })
            .error(function(error) {
                $scope.status = error.status === '404' ? 'empty' : 'error';
            });
    }

    function join(list) {
        list = angular.copy(list);
        list.sort();

        if (list.length == 1) {
            return list[0];
        }

        let last = list.pop();
        return list.join(", ") + " and " + last;
    }

    function updateAddThisDetails() {
        let c = $scope.collection;

        addthis_share.title = "HuNI Public Collection: " + c.name;

        let users = _.pluck(c.owners, 'name');
        addthis_share.description = "Created by " + join(users) + ".\n"
            + 'Shared under HuNI’s Data Policy. See https://huni.net.au/#/data-policy';

        addthis_share.passthrough.twitter.text = addthis_share.title + "\n" + addthis_share.description;

    }

    function init() {
        $scope.isPublic = !pathHas('myhuni');
        //$scope.collection = undefined;
        getCollection($routeParams.id);
    }

    init();
}]);
