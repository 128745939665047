angular.module('huni').factory('CollectionRole', [
'$log',
function ($log) {

    const read_only_perms  = 'view_records';
    const read_write_perms = `edit_notes,add_records,${ read_only_perms }`;
    const owner_perms      = `publish,edit_title,delete,manage_users,${ read_write_perms }`;

    function makePermissions(permissionsList) {
        var permissions = { };
        _.each(permissionsList.split(','), permission => {
            permissions['can_' + permission] = true;
        });
        return permissions;
    }

    const roleInfo = {
        'owner': {
            order:       0,
            description: 'an owner',
            name:        'Owner',
            permissions: makePermissions(owner_perms),
        },

        'read-write': {
            order:       1,
            description: 'an editor',
            name:        'Editor',
            permissions: makePermissions(read_write_perms),
        },

        'read-only': {
            order:       2,
            description: 'a viewer',
            name:        'Viewer',
            permissions: makePermissions(read_only_perms),
        },
    };

    function permissions(roleId) {
        let role = roleInfo[roleId];
        if (!role) {
            $log.error(`Unknown role ${roleId}`);
            return { };
        }
        return role.permissions;
    }

    function options() {
        return _.chain(_.keys(roleInfo))
                .sortBy(roleId => roleInfo[roleId].order)
                .map(roleId => ({ id: roleId, name: roleInfo[roleId].name }))
                .value();
    }

    function getInfo(roleId) {
        return angular.copy(roleInfo[roleId]);
    }

    return {
        getInfo:        getInfo,
        options:        options,
        permissions:    permissions,
    };

}]);
