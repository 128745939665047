angular.module('huni').value('SearchFacets', {
    providerNameTable: {
        ACMI: "Australian Centre for the Moving Image",
        ADB: "Australian Dictionary of Biography",
        AFIRC: "AFI Research Collection",
        AMHD: "Australian Media History Database",
        ANMM: "Australian National Maritime Museum",
        AUS: "Australian Datasets",
        AusStage: "AusStage",
        AustLit: "AustLit",
        "AU-APFA": "Colonial Australian Popular Fiction",
        AWAP: "The Australian Women's Register",
        Bonza: "BONZA - National Cinema and Television Database",
        CAARP: "CAARP - Cinema and Audiences Research Project database",
        CAN: "Canadian Datasets",
        DAAO: "Design and ART Australia Online",
        EMEL: "Encyclopedia of Melbourne",
        EOAS: "Encyclopaedia of Australian Science",
        FCAC: "Find and Connect ACT",
        FCNA: "Find and Connect Australia",
        FCNS: "Find and Connect NSW",
        FCNT: "Find and Connect NT",
        FCQD: "Find and Connect Qld",
        FCSA: "Find and Connect SA",
        FCTS: "Find and Connect Tas",
        FCVC: "Find and Connect Vic",
        FCWA: "Find and Connect WA",
        GOLD: "Electronic Encyclopedia of Gold in Australia",
        HuNI: "HuNI Collections",
        MAP: "Media Archives Project",
        MVC: "Museums Victoria Collections",
        MURA: "Mura® the AIATSIS Collections Catalogue",
        OA: "Obituaries Australia",
        PDSC: "PARADISEC: Pacific and Regional Archive for Digital Sources in Endangered Cultures",
        SAUL: "Saulwick Polls and Social Research",
        Trove: "Trove",
        TUGG: "TUGG",
        UNSW: "University of New South Wales",
    },
    entityTypes: [
        'Concept', 'Event', 'Organisation', 'Person', 'Place', 'Work',
    ],
});
