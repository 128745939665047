angular.module('huni').controller('SearchController', [
    '$scope', '$location',
function SearchController($scope, $location) {

    function init() {
        $scope.query = "";
    }

    // search
    $scope.search = function() {
        // send us to the new view then do the search
        var search = $scope.query;
        if (search === '') { search = '*'; }
        $location.url('/results?q=' + search);
    };

    init();
}]);
