angular.module('huni').controller('CollectionDetailsDialogController', [
'$scope', '$modalInstance', 'collection', 'permissions',
function ($scope, $modalInstance, collection, permissions) {
    $scope.name         = collection.name;
    $scope.synopsis     = collection.synopsis;
    $scope.permissions  = permissions;
    $scope.title        = permissions.can_edit_title
                            ? "Collection Details" : "Collection Notes";

    $scope.close = function() {
        // What fields we can save depends on our user level. We already check
        // permissions to determine what we can edit. Rather than check the
        // permissions again here, just return what has changed. If we can
        // change it, we should be able to save it.
        let fields = [ 'name', 'synopsis' ];
        let updatedFields = _.filter(fields, f => $scope[f] != collection[f]);
        let updates = _.pick($scope, updatedFields);

        return $scope.$close(updates);
    };
}]);
