'use strict';

var addthis_share = { };

angular.module('huni', [
    'ngAnimate', 'ngCookies', 'ngRoute',
    'ui.bootstrap', 'ng.shims.placeholder',
    'angulartics', 'angulartics.google.analytics', 'summernote',
]);

angular.module('huni').config(['$routeProvider',
function($routeProvider) {
    var publicRoutes = {
        '/':  {
            redirectTo: '/search',
        },
        '/search':  {
            templateUrl: 'partials/search.html',
            controller: 'SearchController',
            title: 'Humanities Networked Infrastructure',
        },
        '/collections': {
            templateUrl: 'partials/collections.html',
            controller: 'CollectionListController',
            title: 'Collections',
        },
        '/collection/:id': {
            templateUrl: 'partials/collection.html',
            controller: 'CollectionController',
            title: 'Collection',
        },
        '/link/:id': {
            templateUrl: 'partials/link.html',
            controller: 'LinkController',
            title: 'Link',
        },
        '/results': {
            templateUrl: 'partials/results.html',
            controller: 'ResultsController',
            reloadOnSearch: false,
            title: 'Search Results',
        },
        '/record/:docid': {
            templateUrl: 'partials/network.html',
            controller: 'NetworkController',
            title: 'Record View',
        },
        '/record/:docid/:to_docid': {
            templateUrl: 'partials/network.html',
            controller: 'NetworkController',
            title: 'Record View',
        },
        '/contact': {
          templateUrl: 'partials/contact.html',
          controller: 'NewsletterListController',
          title: 'Contact',
        },
        '/login': {
            templateUrl: 'partials/login.html',
            controller: 'LoginController',
            title: 'Login',
        },
        '/user/:id': {
            templateUrl: 'partials/user.html',
            controller: 'UserController',
            title: 'User',
        },
        '/404': {
            templateUrl: 'partials/404.html',
            title: 'Page not found',
        },
    };

    var staticRoutes = [
        'about', 'data-policy', 'glossary', 'help', 'partners', 'technologies',
        'bibliography', 'history', 'governance', 'terms-conditions',
    ];
    _.each(staticRoutes, function(s) {
        var path = '/' + s;
        var title = s.substr(0, 1).toUpperCase() + s.substr(1); // ucfirst
        var templateUrl = 'partials/' + s + '.html';
        publicRoutes[path] = {
            templateUrl: templateUrl,
            title:       title,
        };
    });

    var privateRoutes = {
        '/myhuni/collections': {
            templateUrl: 'partials/myhuni/collections.html',
            controller: 'MyHuniCollectionsListController',
            title: 'My Collections',
        },
        '/myhuni/collection/:id': {
            templateUrl: 'partials/collection.html',
            controller: 'CollectionController',
            title: 'My Collection',
        },
        '/myhuni/collection/:id/manage': {
            templateUrl: 'partials/collection.html',
            controller: 'CollectionController',
            title: 'My Collection',
        },
        '/myhuni/collection/:id/users': {
            templateUrl: 'partials/myhuni/collection-users.html',
            controller: 'CollectionUsersController',
            title: 'My Collection Users',
        },
        '/myhuni/links': {
            templateUrl: 'partials/myhuni/links.html',
            controller: 'MyHuniLinkController',
            title: 'My Links',
        },
        '/myhuni/profile': {
            templateUrl: 'partials/myhuni/profile.html',
            controller: 'MyHuniProfileController',
            title: 'My Profile',
        },
        '/cms': {
            templateUrl: 'partials/cms.html',
            controller: 'CMSController',
            title: 'CMS',
            needsCanEdit: true,
        },
    };

    function setRoutes(routes, needsLogin) {
        _.each(routes, function(properties, path) {
            properties.needsLogin = needsLogin;
            if (!properties.resolve) {
                properties.resolve = {};
            }
            properties.resolve.user = ['UserMgtService', function(user) {
                return user.loginStatus();
            }];
            $routeProvider.when(path, properties);
        });
    }

    setRoutes(publicRoutes,  false);
    setRoutes(privateRoutes, true);

    $routeProvider.otherwise({redirectTo: '/404'});
}]);

angular.module('huni').run([
    '$rootScope', '$route', '$location', '$anchorScroll', '$analytics',
function($rootScope, $route, $location, $anchorScroll, $analytics) {

        $rootScope.$on("$routeChangeSuccess",
            function(e, currRoute, prevRoute) {

                if (currRoute.locals.user && currRoute.locals.user.newUser) {
                    // Redirect new users to the profile page
                    $location.path('/myhuni/profile');
                }
                else if ($route.current.needsLogin) {
                    // Send the user to the login page if necessary
                    var user = currRoute.locals.user;
                    var loggedIn = user && user.loggedIn;
                    var canEdit  = user && user.canEdit;
                    if (!loggedIn) {
                        var redir = $location.absUrl();
                        $location.path('/login').search({r: redir});
                    }
                    else if ($route.current.needsCanEdit && !canEdit) {
                        $location.path('/404');
                    }
                }

                // Change page title, based on route information
                $rootScope.title = $route.current.title;
                $anchorScroll(); // back to the top!

                // Replace the '#' in the url with share.
                //   eg. https://huni.net.au/share/search?c=Concept
                // This allows things like facebook to scrape pages properly.
                var addthis_url = $location.absUrl().replace('#', 'share');
                var addthis_description = 'Shared under HuNI’s Data Policy. See https://huni.net.au/#/data-policy';

                addthis_share = {
                    url:         addthis_url,
                    url_transforms: {
                        // The right way to share the query params
                        add: $location.search(),
                    },
                    title:       $route.current.title,
                    description: addthis_description,
                    media:       'https://huni.net.au/images/huni-shared.png',
                    passthrough : {
                        twitter: {
                            text: $route.current.title + "\n" + addthis_description,
                        }
                    }
                };

                $rootScope.addthis = addthis_share;
            });

        // Set the path without triggering a new route
        // http://stackoverflow.com/questions/14974271/can-you-change-a-path-without-reloading-the-controller-in-angularjs
        $location.sneakyPath = function(newPath) {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess',
                function () {
                    $route.current = lastRoute;
                    un();
                });
            var ret = $location.path(newPath);
            $analytics.pageTrack(newPath);
            return ret;
        };
}]);
