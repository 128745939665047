angular.module('huni').directive('loadingStatus', [
    '$timeout',
function($timeout) {

    var defaultMessage = {
        empty: "Sorry. We're unable to locate what you're looking for.",
        error: "Sorry. We've crashed!",
        loading: "Please wait. We're just loading your request.",
    };

    function link(scope, element, attrs) {
        // Update any missing message attributes with the defaults.
        _.each(defaultMessage, function (msg, key) {
            var sym = key + 'Message';
            attrs[sym] = attrs[sym] || msg;
        });
    }

    var scope = {
        status: '=',
    };
    // Create the message attributes eg. scope.emptyMessage = '@'
    _.each(_.keys(defaultMessage), function (key) {
        scope[key + 'Message'] = '@';
    });

    return {
        link:           link,
        restrict:       'E',
        scope:          scope,
        templateUrl:    'partials/directives/loading-status.html',
    };
}]);
