angular.module('huni').factory('FacetService', [
'$log',
function ($log) {

    // fieldDefinitions is a hash of fieldName => displayName
    function create(displayName, facetName, queryParam, fieldDefinitions) {

        let fields = _.chain(fieldDefinitions)
                      .map(makeField)
                      .sortBy('displayName')
                      .value();

        function getSelectedFieldNames() {
            return _.chain(fields)
                    .where({ isSelected: true })
                    .pluck('key')
                    .value();
        }

        function getTotalCount() {
            let allTotal = 0;
            let selectedTotal = 0;

            // If any of the facets are selected, the total of the selected
            // ones gives the count. If none are selected, the total of all.
            _.each(fields, field => {
                if (field.isSelected) {
                    selectedTotal += field.count;
                }
                allTotal += field.count;
            });

            return selectedTotal > 0 ? selectedTotal : allTotal;
        }

        function getUrlQueryParams(params) {
            let selection = getSelectedFieldNames();
            if (selection.length > 0) {
                params[queryParam] = selection;
            }
        }

        function makeField(displayName, key) {
            return {
                key,
                displayName,

                count:        0,
                isSelected:   false,
            };
        }

        function setCounts(histogram) {
            _.each(fields, field => {
                field.count = histogram[field.key] || 0;
            });
        }

        function setSelection(selection) {
            if (_.isUndefined(selection)) {
                return;
            }

            if (! _.isArray(selection)) {
                selection = [ selection ];
            }

            // Create a lookup hash from selection array.
            let isSelected = _.indexBy(selection, _.isIdentity);
            _.each(fields, field => {
                field.isSelected = _.has(isSelected, field.key);
            });
        }

        return {
            displayName,
            facetName,
            queryParam,

            fields,

            getSelectedFieldNames,
            getTotalCount,
            getUrlQueryParams,

            setCounts,
            setSelection,
        };
    }

    return {
        create,
    };

}]);
