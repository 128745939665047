angular.module('huni').controller('NewsletterListController', [
    '$scope', '$http', 'cfg', '$routeParams', '$location', 'HuniBackend',
function ($scope, $http, cfg, $routeParams, $location, huniBackend) {

    function init() {
        $scope.loadNewsletters();
    }

    $scope.loadNewsletters = function() {
        $scope.status = 'loading';
        var newsletters_base = cfg.newsletters || '';
        $http.get(newsletters_base + '/api-createsend-com/huni-newsletters')
            .success(function(data) {
                $scope.status = data.length === 0 ? 'empty' : 'ok';
                $scope.newsletters = data;
            })
            .error(function() { $scope.status = 'error'; });
    };

    init();
}]);
