angular.module('huni').factory('FacetSetService', [
'$log', 'FacetService', 'SearchFacets',
function($log, facetService, searchFacets) {

    function create(queryParams) {
        let facetList = [
            facetService.create('Category', 'entityType', 'c',
                _.object(searchFacets.entityTypes,
                         searchFacets.entityTypes)),
            facetService.create('Data Provider', 'sourceAgencyCode', 's',
                searchFacets.providerNameTable),
        ];

        let facetByfacetName = _.indexBy(facetList, 'facetName');

        function getSelectedFieldNames() {
            let selections = { };
            _.each(facetList, function(facet) {
                let selection = facet.getSelectedFieldNames();
                if (selection.length > 0) {
                    selections[facet.facetName] = selection;
                }
            });
            return selections;
        }

        function getTotalCount() {
            // Any one of these will do, might as well do the smallest one.
            //_.each(facetList, facet => facet.getTotalCount());
            return facetList[0].getTotalCount();
        }

        function getUrlQueryParams() {
            let params = { };
            _.each(facetList, function(facet) {
                facet.getUrlQueryParams(params);
            });
            return params;
        };

        function setCounts(facetName, histogram) {
            var facet = facetByfacetName[facetName];
            facet.setCounts(histogram);
        }

        _.each(facetList, facet => {
            facet.setSelection(queryParams[facet.queryParam]);
        });

        return {
            facets:             facetList,
            facetNames:         _.pluck(facetList, 'facetName'),

            getSelectedFieldNames,
            getTotalCount,
            getUrlQueryParams,
            setCounts,
        };
    }

    return {
        create,
    }
}]);
