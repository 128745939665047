angular.module('huni').controller('CollectionUsersController', [
'$scope', '$rootScope', '$routeParams', '$location', 'CollectionRole', 'HuniBackend', 'UserMgtService',
function($scope, $rootScope, $routeParams, $location, collectionRole, huni, userMgt) {

    const collectionId = $routeParams.id;

    function showSuccess(message) {
        $scope.userAlert = {
            type: 'success',
            message: message,
        };
    }

    function showError(message) {
        $scope.userAlert = {
            type: 'danger',
            message: message,
        };
    }

    function getUser() {
        return userMgt.loginStatus().then(
            user => { $scope.user = user },
            error => { $scope.status = 'error' },
        );
    }

    function getCollectionUsers() {
        return huni.get('collection/' + collectionId + '/user')
            .success(result => {
                $rootScope.title = result.name + ' - ' + 'My Collection';
                // Filter the active user from the list.
                $scope.users =
                    _.reject(result, user => user.user_id == $scope.user.id);

                $scope.status = 'ok';
            })
            .error(error => {
                if (error.status == 404) {
                    $location.path("/404");
                }
                else {
                    $scope.status = 'error';
                }
            });
    }

    function canModify(user) {
        if (user.role_id != 'owner') {
            // You can modify non-owners.
            return true;
        }

        if (user.inviting_user_id == $scope.user.id) {
            // You can also modify owners, but only if they were added by you.
            return true;
        }

        return false;
    }

    function getCollectionInfo() {
        return huni.get('collection/' + collectionId)
            .success(result => {
                $scope.collection = result;
                $rootScope.title = result.name + ' - Collection Users';
            })
            .error(error => {

            });
    }

    function roleDescr(role_id) {
        return collectionRole.getInfo(role_id).description;
    }

    function updateRole(user) {
        const path = '/collection/' + collectionId
                   + '/user/' + user.user_id
                   + '/' + user.role_id;

        huni.put(path)
            .success(result => {
                showSuccess(`${ user.user.name } is now ${ roleDescr(user.role_id) }`);
            })
            .error(error => { showError("Updating user failed. Try again?") });
    }

    function addUser() {
        const name    = $scope.newUser.user.name;
        const user_id = $scope.newUser.user.id;
        const role_id = $scope.newUser.role_id;

        const path = '/collection/' + collectionId
                   + '/user/' + user_id
                   + '/' + role_id;

        huni.put(path)
            .success(result => {
                showSuccess(`Added ${ name } as ${ roleDescr(role_id) }`);
                $scope.newUser.user = null;
                $scope.users.push(result);
            })
            .error(error => {
                showError(`Adding ${ name } failed. Try again?`);
            });
    }

    function removeUser(user) {
        const path = '/collection/' + collectionId
                   + '/user/' + user.user_id;

        huni.delete(path)
            .success(result => {
                $scope.users = _.reject($scope.users,
                    u => u.user_id === user.user_id);
                showSuccess(`${ user.user.name } removed from collection`);
             })
            .error(error => { showError("Error removing user. Try again?") });
    }

    function userTypeahead(prefix) {
        const path = '/collection/' + collectionId
                   + '/user/suggest?q=' + window.encodeURIComponent(prefix);

        return huni.get(path).then(response => response.data);
    }

    let roleOptions = collectionRole.options();
    let newUser = { role_id: "read-only" };

    function init() {
        $scope.status = 'loading';
        getUser().then(getCollectionUsers);
        getCollectionInfo();

        _.extend($scope, {
            addUser,
            canModify,
            newUser,
            removeUser,
            roleOptions,
            updateRole,
            userTypeahead,
        });
    }

    init();
}]);
