angular.module('huni').directive('userList', [
function() {

    function link(scope, element, attr) {

    }

    return {
        restrict: 'E',
        scope: {
            users: '=',
        },
        templateUrl: 'partials/directives/user-list.html',
        link: link,
    };
}]);
