angular.module('huni').directive('recordCount',
function() {
    return {
        restrict: 'E',
        scope: {
            count: '@',
        },
        templateUrl: 'partials/directives/record-count.html',
    };
});
