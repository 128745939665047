angular.module('huni').directive('querySnapin', [
    '$rootScope', '$location',
function($rootScope, $location) {

    function link(scope, element, attrs) {

        scope.isActive = false;
        scope.name = 'query';

        function setActive(isActive) {
            if (scope.isActive !== isActive) {
                scope.isActive = isActive;
                $rootScope.$broadcast('SnapInStateChangedEvent', scope);
            }
        }

        function reset() {
            scope.fromRecord = undefined;
            scope.errorCode  = '';
            setActive(false);
            window.onbeforeunload = null;
        }

        function addRecord(record) {
            if (_.isUndefined(scope.fromRecord)) {
                scope.fromRecord = record;
                setActive(true);
                $rootScope.$broadcast('QuerySnapInChangedEvent');
            }
            else {
                findShortestPath(record);
            }
        }

        function findShortestPath(toRecord) {
            // Use url() here not path() as we want to blow away any query
            // parameters.
            let from = scope.fromRecord.docid;
            let to   = toRecord.docid;
            $location.url(`/record/${from}/${to}`);
            reset();
        };

        scope.findReachableNodes = function() {
            let docid = scope.fromRecord.docid;
            $location.search({ docid: docid });
            $location.path('/results');
            reset();
        };

        scope.close = function() {
            reset();
        };

        reset();
        $rootScope.$on('AddRecordToQueryEvent', function(ev, record) {
            addRecord(record);
        });
        $rootScope.$on('EditCollectionEvent', scope.close);
    }

    return {
        restrict: 'E',
        scope: {
        },
        templateUrl: 'partials/snapins/query.html',
        link: link,
    };
}]);
