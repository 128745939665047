angular.module('huni').directive('collectionButton',
    function() {

        function link(scope, element, attrs) {

            function isEnabled() {
                // If there is a snapin and it's not the collection snapin,
                // then the button is disabled.
                // If there is no snapin, or that snap is the collection,
                // the the button is enabled.
                return (!scope.snapIn || (scope.snapIn.name === 'collection'));
            }

            function getMode() {
                if (!scope.snapIn)                      { return 'default'; }
                if (scope.snapIn.name !== 'collection') { return 'default'; }
                if (!scope.record.collections)          { return 'add';     }

                // If this record is in the selected collection, then the
                // mode is remove (false), otherwise add.
                return _.has(scope.record.collections,
                             scope.snapIn.selectedCollection.id)
                                ? 'remove' : 'add';
            }

            function refreshState() {
                var isDisabled = !isEnabled();
                if (scope.isDisabled !== isDisabled) {
                    scope.isDisabled = isDisabled;
                }

                scope.mode = getMode();
            }

            scope.click = function() {
                scope.$emit('CollectionRecordChangeEvent',
                                scope.record, scope.mode !== 'remove');
            };

            scope.$watch('snapIn', refreshState);
            scope.$watch('record', refreshState);
            scope.$watchCollection('record.collections', refreshState);
            refreshState();
        }

        return {
            restrict: 'E',
            scope: {
                record: '=',
                snapIn: '=',
            },
            templateUrl: 'partials/buttons/collection.html',
            link: link,
        };
    }
);
