angular.module('huni').filter('truncate', function() {
    // Truncates the input text on word boundaries
    return function(input, maxLength, isDisabled) {
        if (isDisabled || input === undefined ||
            input === null || input.length <= maxLength) {
            return input;
        }

        while (input[maxLength] !== ' ') {
            maxLength += 1;
            if (maxLength === input.length) {
                return input;
            }
        }

        return input.substr(0, maxLength) + '...';
    };
});
