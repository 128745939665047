angular.module('huni').directive('collectionList', [
    '$location', 'CollectionRole', 'DialogService', 'HuniBackend', 'UserMgtService',
function($location, collectionRole, dialog, huni, userMgt) {

    function link(scope, element, attr) {
        // directive parameters are stringified(?)
        scope.public = scope.isPublic === 'true';

        if (_.isUndefined(attr.userAlert)) {
            // set a default alert object in case the user hasn't
            attr.userAlert = { };
        }

        function setMessage(type, msg) {
            scope.userAlert.type    = type;
            scope.userAlert.message = msg;
        }

        function ok(msg)    { setMessage('success', msg); }
        function error(msg) { setMessage('danger',  msg); }

        scope.roleOptions = collectionRole.options();

        scope.roleName = function(roleId) {
            return collectionRole.getInfo(roleId).name;
        };

        function yesDeleteCollection(c) {
            huni.delete('collection/' + c.id)
                .success(function(response) {
                    var index = scope.collections.indexOf(c);
                    scope.collections.splice(index, 1);
                    ok('Deleted collection "' + c.name + '"');
                })
                .error(function() {
                    error('There was an error deleting collection "' + c.name + '"');
                })
                ;
        }

        scope.activateCollection = function(c) {
            let loc = `${ scope.baseUri }/${ c.id }/manage`;
            if (loc[0] == '#') {
                loc = loc.substring(1);
            }
            $location.url(loc);
        };

        scope.manageCollectionUsers = function(c) {
            let loc = `${ scope.baseUri }/${ c.id }/users`;
            if (loc[0] == '#') {
                loc = loc.substring(1);
            }
            $location.url(loc);
        };

        // Delete a whole collection
        scope.deleteCollection = function(c) {
            var msg = 'Really delete collection "' + c.name + '"?';
            dialog.confirmationDialog(msg)
                .then(function() { yesDeleteCollection(c); });
        };

        // This hides collections that were un-published while we were
        // looking at them.
        scope.isVisible = function(collection) {
            var ret = !scope.public || collection.is_public;
            return ret;
        };

        scope.$on('CollectionDetailsEvent', function(e, collection) {
            var oldCollection = _.findWhere(scope.collections,
                { id: collection.id });
            _.extend(oldCollection, collection);
        });

        scope.canChangeRole = function(collection) {
            if (collection.role_id != 'owner') {
                return false;
            }
            if (collection.owners.length == 1) {
                return false;
            }
            return true;
        };

        function removeRole(collection) {
            const path = '/collection/' + collection.id
                       + '/user/' + scope.user.id;

            return huni.delete(path).then(
                success => {
                    ok(`Removed "${ collection.name }" from your collections`);
                    scope.collections = _.reject(scope.collections, i =>
                                i.id == collection.id);
                },
                failure => {
                    error(`There was an error removing "${ collection.name }" from your collections`);
                },
            );
        }

        function changeRole(collection, roleId) {
            const path = '/collection/' + collection.id
                       + '/user/' + scope.user.id
                       + '/' + roleId;
            const desc = collectionRole.getInfo(roleId).description;

            return huni.put(path).then(
                success => {
                    ok(`You are now ${ desc } on collection "${ collection.name }"`);
                },
                failure => {
                    error(`There was an error changing you to ${ desc } on collection "${ collection.name }"`);
                });
        }

        scope.onRoleChange = function(collection, oldRoleId) {
            let isRemoval = (collection.role_id == 'remove');
            let msg = isRemoval
                ? `This will remove collection "${ collection.name }" from your collections.`
                : `This will remove your ownership rights from collection "${ collection.name }".`;

            let result = dialog.confirmationDialog(msg + "<br><br>You will not be able to undo this change. Are you sure?");

            result.then(
                ok => {
                    if (isRemoval) {
                        removeRole(collection);
                    }
                    else {
                        changeRole(collection, collection.role_id);
                    }
                },
                cancel => {
                    collection.role_id = oldRoleId;
                });
        };

        scope.permissions = function(collection) {
            return collectionRole.permissions(collection.role_id);
        }

        userMgt.loginStatus().then(user => { scope.user = user });
    }

    return {
        restrict: 'E',
        scope: {
            baseUri:      '@',
            collections:  '=',
            isPublic:     '@',
            userAlert:    '=',
        },
        templateUrl: 'partials/directives/collection-list.html',
        link: link,
    };
}]);
