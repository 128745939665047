angular.module('huni').filter('sort', function() {
    function case_insensitive(lhs, rhs) {
        return lhs.toLowerCase().localeCompare(rhs.toLowerCase());
    }

    // Take an array as input and returns the sorted array
    return function(input) {
        if (! _.isArray(input)) {
            return input;
        }
        return input.sort(case_insensitive);
    };
});
