angular.module('huni').controller('CreateLinktypeDialogController', [
    '$scope', '$modalInstance', 'HuniBackend',
    'fromRecord', 'toRecord', 'suggestions', 'lhsName',
function CreateLinktypeDialogController
    ($scope, $modalInstance, huniBackend,
     fromRecord, toRecord, suggestions, lhsName) {

    function getSuggestions(key) {
        return _.chain(suggestions)
                .pluck(key)
                .sort()
                .uniq(true)
                .value();
    }

    function init() {
        $scope.fromRecord = fromRecord;
        $scope.toRecord   = toRecord;
        $scope.names = getSuggestions('name');
        $scope.pairs = getSuggestions('pair');
        $scope.data = {
            name: lhsName,
            pair: '',
            symmetric: false,
        };
    }

    $scope.ok = function() {
        var pair = $scope.data.symmetric ? $scope.data.name : $scope.data.pair;
        huniBackend.post('linktype', {
            name: $scope.data.name,
            pair: pair,
            from: $scope.fromRecord.entityType,
            to:   $scope.toRecord.entityType,
        }).success(function(result) {
            //TODO: post linktype should return this pair
            result.pair = pair;
            $modalInstance.close(result);
        });
    };

    init();
}]);
