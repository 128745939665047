angular.module('huni').directive('recordSummary',
function() {
    return {
        restrict: 'E',
        scope: {
            record: '=',
        },
        templateUrl: 'partials/directives/record-summary.html',
    };
});
