angular.module('huni').controller('MyHuniProfileController', [
    '$rootScope', '$scope', 'HuniBackend',
function ($rootScope, $scope, huni) {
    function setMessage(type, msg) {
        $scope.userAlert.type    = type;
        $scope.userAlert.message = msg;
    }

    function ok(msg)    { setMessage('success', msg); }
    function error(msg) { setMessage('danger',  msg); }

    function getProfile() {
        $scope.status = 'loading';
        huni.get('profile')
            .success(function(response) {
                $scope.user = response;
                $scope.status = 'ok';
            })
            .error(function() {
                $scope.status = 'error';
            });
    }

    $scope.update = function() {
        huni.put('profile', $scope.user)
            .success(function() {
                ok('Your user profile was updated successfully.');
                $rootScope.$broadcast('ProfileChangedEvent', $scope.user);
            })
            .error(function() {
                error('There was a problem saving your user profile.');
            });
    };

    function init() {
        $scope.userAlert = { };
        if ($scope.user.newUser) {
            // If we've detected a new user, say hello, and then behold as they
            // immediately become oldbies.
            $scope.user.newUser = false;
            ok('Welcome to HuNI!');
        }
        getProfile();
    }

    init();
}]);
