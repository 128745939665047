angular.module('huni').factory('DelayedCallService', [
'$log', '$q', '$timeout',
function($log, $q, $timeout) {

    function wrap(name, func, delayMillis) {
        let pending = null;

        function cancelPending() {
            if (pending) {
                //$log.info(`Cancelling pending ${name} call`);
                $timeout.cancel(pending);
                pending = null;
            }
        }

        // The delayed version will cancel any pending calls, and schedule a
        // new call after delayMillis milliseconds.
        function delayed() {
            //$log.info(`Scheduling ${name} call in ${delayMillis}ms`);
            cancelPending();

            let args = arguments;
            function later() {
                pending = null;
                //$log.info(`Calling scheduled ${name} call`);
                return func.apply(func, args);
            }

            pending = $timeout(later, delayMillis);
            return pending;
        }

        // The immediate version will cancel any pending calls, and immediately
        // call the function. It returns an already-resolved promise.
        function immediate() {
            //$log.info(`Calling ${name} immediately`);
            cancelPending();

            let deferred = $q.defer();
            deferred.resolve(func.apply(undefined, arguments));
            return deferred.promise;
        }

        return {
            delayed,
            immediate,
        };
    }

    // This module exports a single function.
    return wrap;
}]);
