angular.module('huni').factory('SolrTextSearch', [
'$filter', '$log', '$q', 'DelayedCallService', 'FacetSetService', 'SolrSearchService',
function($filter, $log, $q, delayedCall, facetSetService, solr) {

    function newSearch(queryParams, options) {
        let facetSet = facetSetService.create(queryParams);

        const recordsPerPage = 10;
        const searchDelayMillis = 1200;

        var search = {
            hasUserString: true,
            title: "Quick Search Results",
            userString: queryParams.q || '*',    // what the user has typed
            currentPage: queryParams.p || 1,  // currentPage
            // if queryParams.p > 1, then we need to set totalRecords to match,
            // otherwise the pagination controller freaks out and resets the
            // page to 1
            totalRecords:        // count of all records matching this search
                queryParams.p * recordsPerPage || 1,
            recordsPerPage: recordsPerPage,     // page size
            records: [],            // this pages worth of records
            facet: facetSet,
            status: '',             // user-facing search progress status
        };

        function makeFacetQueryString(facetName) {
            return `facet=true&facet.limit=-1&facet.field=${facetName}`;
        }

        function makeFilterQueryString(facetNameToSkip) {
            let selections = _.omit(facetSet.getSelectedFieldNames(),
                                    facetNameToSkip);

            var fieldQueries = [];
            _.each(selections, (selectedFieldNames, facetName) => {
                var q = _.map(selectedFieldNames, key => facetName + ':' + key);
                fieldQueries.push('&fq=(' + q.join(' OR ') + ')');
            });
            return fieldQueries.join('');
        }

        // Not escaping star as we want that for the user
        let solrSpecialChars = /[+:&|!(){}\[\]\^"~?:\\]/g;

        function makeTextQueryString() {
            var terms = search.userString.split(/ /);
            var clauses = _.map(terms,
                function(s) {
                    var esc = s.replace(solrSpecialChars, '\\$&');
                    esc = encodeURIComponent(esc);
                    // Just search on text, not text_rev.
                    return '(text:' + esc + ')';
                });
            clauses.push('NOT (isDeleted:*)');
            if (clauses.length > 0) {
                return clauses.join(' AND ');
            }
            return '(text:*)';
        }

        function finishSearchRaw(promisesFunc) {
            search.isBusy = true;
            return $q.all(promisesFunc()).then(
                result => {
                    if (_.has(result, 'records')) {
                        search.records = result.records.records;
                        search.totalRecords = result.records.total;
                    }

                    if (_.has(result, 'facets')) {
                        _.each(result.facets, (histogram, facetName) => {
                            facetSet.setCounts(facetName, histogram);
                        })
                    }

                    if (search.totalRecords > 0) {
                        let total = search.totalRecords;
                        let first = (search.currentPage - 1)
                                        * search.recordsPerPage + 1;
                        let last = first + search.recordsPerPage - 1;
                        if (last > total) {
                            last = total;
                        }
                        let number = $filter('number');
                        search.status = number(first) + ' - ' + number(last)
                                      + ' of ' + number(total);
                        search.loadingStatus = 'ok';
                    }
                    else {
                        search.status = 'No records found';
                        search.loadingStatus = 'empty';
                    }
                },
                failure => {
                    search.status = 'Search failed. Try again later';
                    search.loadingStatus = 'error';
                },
            ).finally(result => search.isBusy = false);
        }

        let finishSearch = delayedCall('SolrSearch',
                                       finishSearchRaw,
                                       searchDelayMillis);

        function doSearch(when, promisesFunc) {
            search.records = [ ];
            search.status = 'Searching';
            search.loadingStatus = 'loading';

            finishSearch[when](promisesFunc);
        }

        function facetSearch(facetNameToSkip) {
            let promises = { };
            let facetNames = _.without(facetSet.facetNames, facetNameToSkip);
            _.each(facetNames, facetName => {
                var query = 'q=' + makeTextQueryString(search.userString)
                          + makeFilterQueryString(facetName)
                          + '&' + makeFacetQueryString(facetName)
                          + '&rows=0'
                          ;

                promises[facetName] = solr.doQuery(query).then(response => {
                    var facets =
                        response.data.facet_counts.facet_fields[facetName];

                    // facets is: [ key, count, key, count, ... ]
                    // split those into two arrays, so we can build a
                    // hash using _.object
                    let [ keys, counts ] = _.partition(facets,
                        (value, index) => index % 2 == 0);

                    let histogram = _.object(keys, counts);
                    return histogram;
                });
            });

            return $q.all(promises);
        }

        function recordSearch() {
            let sortString =
                '&sort=score desc,startDate asc,endDate asc,releaseDate asc';
            var query = 'q=' + makeTextQueryString(search.userString)
                      + makeFilterQueryString()
                      + '&rows=' + search.recordsPerPage
                      + '&start=' +
                        (search.currentPage - 1) * search.recordsPerPage
                      + sortString;
                      ;

            return solr.doRecordsQuery(query).then(result => {
                return {
                    total:      result.numFound,
                    records:    result.docs,
                };
            });
        }

        function updateUserString() {
            if (search.userString === '') {
                search.userString = '*';
            }
            search.currentPage = 1;
            search.totalRecords = 0;
            doSearch('immediate', function() { return {
                records: recordSearch(),
                facets:  facetSearch(),
            }});
        };

        function updateFacetSelections(facetNameToSkip) {
            search.currentPage = 1;
            doSearch('delayed', function() { return {
                records: recordSearch(),
                facets:  facetSearch(facetNameToSkip),
            }});
        };

        function gotoPage(page) {
            search.currentPage = page;
            doSearch('immediate', function() { return {
                records: recordSearch(),
            }});
        };

        function getUrlQueryParams() {
            let queryParams = facetSet.getUrlQueryParams(queryParams);
            if (search.userString != '*') {
                queryParams.q = search.userString;
            }
            if (search.currentPage > 1) {
                queryParams.p = search.currentPage;
            }
            return queryParams;
        };

        _.extend(search, {
            getUrlQueryParams,
            gotoPage,
            updateFacetSelections,
            updateUserString,
        });

        doSearch('immediate', function() { return {
            records: recordSearch(),
            facets:  facetSearch(),
        }});

        return search;
    };

    return {
        newSearch:    newSearch,
    };
}]);
