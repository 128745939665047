angular.module('huni').directive('graphViewButton', ['$location',
    function($location) {

        function link(scope, element, attrs) {
            scope.click = function() {
                $location.path('/record/' + scope.docid);
            }
        }

        return {
            restrict: 'E',
            scope: {
                docid: '=',
            },
            templateUrl: 'partials/buttons/graph-view.html',
            link: link,
        };
    }
]);
