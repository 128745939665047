angular.module('huni').controller('CollectionListController', [
    '$scope', '$routeParams', '$location', 'HuniBackend', 'SolrSearchService',
function ($scope, $routeParams, $location, huniBackend, solrSearchService) {

    function init() {
        var params = $location.search();
        $scope.data = {
            page:       params.p || 1,
            rows:       params.n || 10,
            searchTerm: params.q || '',
        };
        // We need this to fake the pagination controller out.
        $scope.data.total = $scope.data.page * $scope.data.rows;
        $scope.collections = [];

        $scope.loadCollections();
    }

    function makeParams() {
        var params = {
            p: $scope.data.page,
            n: $scope.data.rows,
        };
        var search = $scope.data.searchTerm.trim();
        if (search.length > 0) {
            params.q = search;
        }
        return params;
    }

    $scope.reload = function() {
        var params = makeParams();
        var oldParams = $location.search();
        if (! _.isEqual(params, oldParams)) {
            $location.search(params);
        }
    };

    $scope.updatePage = $scope.reload;
    $scope.updateSearchTerm = $scope.reload;

    $scope.loadCollections = function() {
        $scope.status = 'loading';
        huniBackend.get('publication', makeParams())
            .success(function(data) {
                $scope.status = data.total === 0 ? 'empty' : 'ok';
                $scope.data.total = data.total;
                $scope.collections = data.publications;
            })
            .error(function() { $scope.status = 'error'; });
    };

    init();
}]);
