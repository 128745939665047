angular.module('huni').controller('LoginController', [
    '$rootScope', '$scope', '$location', 'HuniBackend',
function ($rootScope, $scope, $location, huni) {

    var opts = {
      lines: 15, // The number of lines to draw
      length: 16, // The length of each line
      width: 3, // The line thickness
      radius: 12, // The radius of the inner circle
      corners: 1, // Corner roundness (0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: '#000', // #rgb or #rrggbb
      speed: 1, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      className: 'spinner', // The CSS class to assign to the spinner
      zIndex: 2e9, // The z-index (defaults to 2000000000)
      top: 'auto', // Top position relative to parent in px
      left: 'auto' // Left position relative to parent in px
    };

    // init - get list and total, available document count
    $scope.onLogin = function(provider) {
        $scope.providers_visible = false;
        var target = document.getElementById('spinner');
        var spinner = new Spinner(opts).spin(target);
        return spinner;
    };

    // The login page gets passed a return-destination redirect value
    // as ?r=$redirect. We pass that onto the login links.
    function makeUri(provider_id) {
        var params = $location.search();
        var uri = huni.uri('login/' + provider_id);
        if (params.r) {
            uri += '?r=' + encodeURIComponent(params.r);
        }
        return uri;
    }

    $scope.providers_visible = true;
    $scope.providers = [];

    // Load the list of active providers from the backend
    huni.get('login').success(function(providers) {
        _.each(providers, function(provider) {
            provider.uri = makeUri(provider.id);
        });
        $scope.providers = providers;
    });
}]);
