angular.module('huni').directive('userListShort', [
function() {

    function link(scope, element, attr) {
        let numberNames = [
            'zero', 'one', 'two', 'three', 'four',
            'five', 'six', 'seven', 'eight', 'nine',
        ];

        function number(n) {
            return numberNames[n] || n;
        }

        function formatList(names) {
            if (names.length == 1) {
                return names[0];
            }

            let last = names.pop();
            return names.join(', ') + ' and ' + last;
        }

        scope.text = function() {
            // 1: A
            // 2: A & B
            // 3: A, B & C
            // 4: A, B & n-2 others
            let names = _.pluck(scope.users, 'name');
            if (names.length >= 4) {
                let others = names.length - 2;
                names.splice(2, others, number(others) + ' others');
            }
            return formatList(names);
        };

        scope.title = function() {
            let names = _.pluck(scope.users, 'name');
            return formatList(names);
        };
    }

    return {
        restrict: 'E',
        scope: {
            users:  '=',
            link:   '@',
        },
        templateUrl: 'partials/directives/user-list-short.html',
        link: link,
    };
}]);
