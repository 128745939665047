angular.module('huni').controller('UserController', [
    '$scope', '$routeParams', 'HuniBackend', 'UserMgtService',
function ($scope, $routeParams, huni, userMgt) {

    const user_id = $routeParams.id;

    huni.get(`profile/${ user_id }`)
        .then(response => {
            $scope.user = response.data;
            return userMgt.loginStatus();
        })
        .then(user => {
            $scope.own_profile = (user.id == $scope.user.id);
        });
}]);
