angular.module('huni').controller('PersistentCollapseController', [
    '$scope', 'SettingsService',
function ($scope, settings) {

    $scope.group  = 'collapse';

    $scope.init = function(key, defaultState) {
        $scope.key = key;
        $scope.defaultState = defaultState;
        $scope.isCollapsed = settings.get($scope.group, $scope.key);
        if (_.isUndefined($scope.isCollapsed)) {
            $scope.isCollapsed = defaultState;
        }
    };

    function setState(isCollapsed) {
        if ($scope.isCollapsed !== isCollapsed) {
            $scope.isCollapsed = isCollapsed;
            if ($scope.isCollapsed === $scope.defaultState) {
                settings.remove($scope.group, $scope.key);
            }
            else {
                settings.set($scope.group, $scope.key, isCollapsed);
            }
        }
    }

    $scope.toggleCollapsed = function() {
        setState(!$scope.isCollapsed);
    };

    $scope.collapse = function() {
        setState(true);
    };

    $scope.expand = function() {
        setState(false);
    };
}]);
