angular.module('huni').service('UserMgtService', [
    '$rootScope', '$q', 'HuniBackend',
function($rootScope, $q, huniBackend) {

    var self = this;

    var userStatus = {};

    function setLoggedIn(data) {
        userStatus.id       = data.user_id;
        userStatus.name     = data.display_name;
        userStatus.newUser  = data.new_user;
        userStatus.canEdit  = data.can_edit;
        userStatus.loggedIn = true;
        userStatus.vlabUp   = true;
        userStatus.owns     = function(object) {
            return object && (object.user_id === userStatus.id);
        };
    }

    function setLoggedOut(vlabUp) {
        userStatus.id       = -1;
        userStatus.name     = '';
        userStatus.newUser  = false;
        userStatus.loggedIn = false;
        userStatus.canEdit  = false;
        userStatus.vlabUp   = vlabUp;
        userStatus.owns     = function() { return false; };
    }

    var loginPromise = $q.defer();
    huniBackend.get('user')
        .success(function(data) {
            if (data.user_id) {
                setLoggedIn(data);
            }
            else {
                setLoggedOut(true);
            }
        })
        .error(function() {
            setLoggedOut(false);
        })
        .finally(function() {
            loginPromise.resolve(userStatus);
        });

    $rootScope.$on('ProfileChangedEvent', function(e, profile) {
        userStatus.name = profile.name;
    });

    self.loginStatus = function() {
        return loginPromise.promise;
    };

    self.logout = function() {
        setLoggedOut(userStatus.vlabUp);    // keep the vlabUp status
        return huniBackend.get('logout');
    };

    setLoggedOut(true);
}]);
