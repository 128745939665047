angular.module('huni').filter('weblink', function() {
    // Take a weblink as input and adds http:// if necessary
    return function(input) {
        if (_.isUndefined(input) || input.match(/^[a-z]*:\/\//i)) {
            return input;
        }

        return 'http://' + input;
    };
});
