angular.module('huni').directive('user', [
function() {

    function link(scope, element, attr) {

    }

    return {
        restrict: 'E',
        scope: {
            user: '=',
        },
        templateUrl: 'partials/directives/user.html',
        link: link,
    };
}]);
