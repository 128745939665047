angular.module('huni').controller('LinkController', [
    '$rootScope', '$scope', '$routeParams',
    'HuniBackend', 'SolrSearchService', 'DialogService',
function ($rootScope, $scope, $routeParams, huni, solr, dialog) {

    function augmentLink(link) {

        return solr.getRecords([link.from_docid, link.to_docid])
            .then(function(records) {
                var record = _.indexBy(records, 'docid');
                link.from = record[link.from_docid];
                link.to   = record[link.to_docid];

                $rootScope.title = link.from.displayName + " → " + link.to.displayName + " - Link";
                $scope.link = link;
                $scope.status = 'ok';
            },
            function () { $scope.status = 'error'; }
          );
    }

    function fetchLink(id) {
        $scope.status = 'loading';
        huni.get('link' + '/' + id)
            .success(augmentLink)
            .error(function() { $scope.status = 'empty'; });
    }

    $rootScope.$on('LinkChangedEvent', function(event, data) {
      _.extend($scope.link, data.link);
    } );

    $scope.editLink = function(link) {
        $scope.$emit('EditLinkEvent', link);
    };

    function init() {
        $scope.link = undefined;
        fetchLink($routeParams.id);
    }

    init();
}]);
