angular.module('huni').directive('linkSnapin', [
    '$rootScope', '$modal', 'DialogService', 'HuniBackend',
function($rootScope, $modal, dialog, huniBackend) {

    var createNew = { name: 'Create new...', pair: 'Create new...' };

    var messages = {
        new1:
            [ 'Step One',        'Choose another record to link with. '
                               + 'You can navigate wherever you like to find other records. '
                               + 'For example start a new search, interact with a network graph, '
                               + 'or view a collection.'                ],

        new2:
            [ 'Step Two',        'Select a relationship'                ],

        new3:
            [ 'Step Three',      'Add a note and save your link'        ],

        new4:
            [ 'Link Created',    'Close this window or keep editing'    ],

        dirty:
            [ 'Unsaved Changes', 'Your link has unsaved changes'        ],

        clean:
            [ 'Link Saved',      'Your link is up to date'              ],

        duplicate:
            [ 'Link already exists', "You've already created this link", true ],

        failed:
            [ 'Save failed',      'Something went wrong. Try saving again', true ],
    };

    function link(scope, element, attrs) {

        scope.isActive = false;
        scope.name = 'link';
        scope.showAlert = true;
        scope.lastMessageCode = '';
        scope.errorCode = '';

        function getAlertMessageCode() {
            if (scope.errorCode)      { return scope.errorCode; }
            if (!scope.toRecord)       { return 'new1'; }
            if (!scope.rw.selectedLHS) { return 'new2'; }
            if (!scope.rw.selectedRHS) { return 'new2'; }
            if (scope.link) {
                // editing an existing link
                return scope.form.$dirty ? 'dirty' : 'clean';
            }
            // creating a new link
            return scope.form.$dirty ? 'new3' : 'new4';
        }

        scope.getAlertMessage = function() {
            var code = getAlertMessageCode();
            var msg = messages[code];
            if (scope.lastMessageCode !== code) {
                scope.showAlert = true;
                scope.lastMessageCode = code;
                scope.alertType = msg[2] ? 'danger' : 'success';
            }
            return '<strong>' + msg[0] + ' </strong>'
                    + '<em>'     + msg[1] + ' </em>';
        };

        // Add a linktype.display member which disambiguates one-to-many
        // linktypes. eg. parent / (child/son/daughter)
        function annotateLinktypes(linktypes) {
            var pairList = _.groupBy(linktypes, 'name');
            var links = [];
            _.each(pairList, function(pairs, name) {
                pairs = _.sortBy(pairs, 'pair');
                pairs.push(createNew);
                links.push({
                    name:  name,
                    pairs: pairs,
                });
            });
            links.push(createNew);
            return links;
        }

        function setActive(isActive) {
            if (scope.isActive !== isActive) {
                scope.isActive = isActive;
                $rootScope.$broadcast('SnapInStateChangedEvent', scope);
            }
        }

        function reset() {
            scope.link       = undefined; // set=edit, undef=new
            scope.fromRecord = undefined;
            scope.toRecord   = undefined;
            scope.rawLinks   = [ ];
            scope.linktypes  = [ ];
            scope.errorCode  = '';
            scope.rw = {
                // XXX: why is the html using a sub-scope?
                selectedLHS: undefined,
                selectedRHS: undefined,
                pairs: [ ],
                linknote: '',
            };
            setActive(false);
            window.onbeforeunload = null;
        }

        function launchLinktypeDialog(lhsName) {
            var modalInstance = $modal.open({
                templateUrl: 'partials/dialogs/create-linktype.html',
                controller: 'CreateLinktypeDialogController',
                resolve: {
                    huniBackend: function() { return huniBackend; },
                    fromRecord:  function() { return scope.fromRecord; },
                    toRecord:    function() { return scope.toRecord; },
                    suggestions: function() { return scope.linktypes; },
                    lhsName:     function() { return lhsName; },
                },
            });

            return modalInstance.result;
        }

        function launchLinkNoteDialog() {
            var modalInstance = $modal.open({
                templateUrl: 'partials/dialogs/link-note.html',
                controller: ['$scope', function($scope) {
                    $scope.linknote = scope.rw.linknote;
                }],
            });

            return modalInstance.result;
        }

        function updateLinkTypes() {
            var uri = 'linktype/' + scope.fromRecord.entityType
                            + '/' + scope.toRecord.entityType;
            return huniBackend.get(uri).success(function(data) {
                scope.rawLinks  = data;
                scope.linktypes = annotateLinktypes(scope.rawLinks);
            });
        }

        function addRecord(record) {
            if (_.isUndefined(scope.fromRecord)) {
                scope.fromRecord = record;
                setActive(true);
            }
            else if (_.isUndefined(scope.toRecord)) {
                scope.toRecord = record;
                updateLinkTypes();
            }
            else {
                var keepLinkTypes =
                    (scope.toRecord.entityType === record.entityType);

                scope.link = undefined;
                scope.toRecord = record;
                if (!keepLinkTypes) {
                    scope.rw.selectedLHS = undefined;
                    scope.rw.selectedRHS = undefined;
                    updateLinkTypes();
                }
            }
            scope.form.$setDirty();
            $rootScope.$broadcast('LinkSnapInChangedEvent');
        }

        function editLink(link) {
            scope.link = link;
            scope.fromRecord = link.from;
            scope.toRecord = link.to;
            scope.rawLinks   = [ ];
            scope.linktypes  = [ ];
            scope.rw.pairs = [ ];
            scope.rw.linknote = link.synopsis;
            scope.rw.selectedLHS = undefined;
            scope.rw.selectedRHS = undefined;
            updateLinkTypes().then(function() {
                scope.rw.selectedLHS =
                    _.findWhere(scope.linktypes, { name: link.linktype });
                scope.rw.selectedRHS =
                    _.findWhere(scope.rw.selectedLHS.pairs,
                        { pair: link.pairtype });
            });
            scope.form.$setPristine();
            $rootScope.$broadcast('LinkSnapInChangedEvent');
            setActive(true);
        }

        scope.lhsChanged = function() {
            scope.errorCode = '';
            if (scope.rw.selectedLHS === createNew) {
                // User chose 'Create new...'
                scope.rw.selectedLHS = undefined;
                scope.createLinktype();
            }
            else if (scope.rw.selectedLHS) {
                // User chose a valid choice
                scope.rw.selectedRHS = scope.rw.selectedLHS.pairs[0];
            }
            else {
                // User moved back to '-- select a relationship --'
                scope.rw.selectedRHS = undefined;
            }
            $rootScope.$broadcast('LinkSnapInChangedEvent');
        };

        scope.rhsChanged = function() {
            scope.errorCode = '';
            if (scope.rw.selectedRHS === createNew) {
                var lhsName = scope.rw.selectedLHS.name;
                scope.rw.selectedLHS = undefined;
                scope.createLinktype(lhsName);
            }
            $rootScope.$broadcast('LinkSnapInChangedEvent');
        };

        scope.createLinktype = function(lhsName) {
            scope.errorCode = '';
            launchLinktypeDialog(lhsName).then(function (linktype) {
                scope.rawLinks.push(linktype);
                scope.linktypes = annotateLinktypes(scope.rawLinks);
                scope.rw.selectedLHS =
                    _.findWhere(scope.linktypes, { name: linktype.name });
                scope.rw.selectedRHS =
                    _.findWhere(scope.rw.selectedLHS.pairs,
                        { pair: linktype.pair });
            });
        };

        scope.editNote = function() {
            scope.errorCode = '';
            launchLinkNoteDialog().then(function(linknote) {
                if (scope.rw.linknote !== linknote) {
                    scope.rw.linknote = linknote;
                    scope.form.$setDirty();
                }
            });
        };

        scope.saveLink = function() {
            scope.errorCode = '';
            var data = {
                from_docid:     scope.fromRecord.docid,
                to_docid:       scope.toRecord.docid,
                linktype_id:    scope.rw.selectedRHS.id,
                synopsis:       scope.rw.linknote,
            };
            var promise;
            var action;
            if (scope.link) {
                // Editing existing link
                promise = huniBackend.put('link/' + scope.link.id, data);
                action = 'modified';
            }
            else {
                // Creating new link
                promise = huniBackend.post('link', data);
                action = 'created';
            }
            promise.success(function(link) {
                scope.link = link;
                $rootScope.$broadcast('LinkChangedEvent', {
                    link: link,
                    action: action,
                });
                scope.form.$setPristine();
                $rootScope.$broadcast('LinkSnapInChangedEvent');
            }).error(function(response, code) {
                if (code == 400) {
                    scope.form.$setPristine(); // we are clean now
                    scope.errorCode = 'duplicate';
                }
                else {
                    scope.errorCode = 'failed';
                }
            });
        };

        scope.close = function(ask) {
            if (ask && scope.isActive) {
                dialog.confirmationDialog('Really close without saving?')
                    .then(reset);
            }
            else {
                reset();
            }
        };

        scope.closeAlert = function() {
            scope.showAlert = false;
        };

        reset();
        $rootScope.$on('AddRecordToLinkEvent', function(ev, record) {
            addRecord(record);
        });
        $rootScope.$on('EditLinkEvent', function(ev, link) {
            editLink(link);
        });
        $rootScope.$on('EditCollectionEvent', scope.close);

        window.onbeforeunload = function() {
            if (!scope.form.$dirty) { return; }
            return 'Your link has not been saved';
        };
    }

    return {
        restrict: 'E',
        scope: {
        },
        templateUrl: 'partials/snapins/link.html',
        link: link,
    };
}]);
