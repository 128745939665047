angular.module('huni').filter('isNonEmpty', function() {
    // Returns true if this will result in /something/ being displayed
    return function(input) {

        if (input === "None")           { return false; }
        if (input === undefined)        { return false; }
        if (input === null)             { return false; }
        if (input.trim().length === 0)  { return false; }

        return true;
    };
});
