angular.module('huni').directive('queryButton',
function() {

    function link(scope, element, attrs) {

        function linkAlreadyExists() {
            var snap = scope.snapIn;
            return _.find(scope.record.links, function(link) {
                return (snap.link.user_id === link.user_id)
                    && (snap.fromRecord.docid === link.to_docid)
                    && (snap.rw.selectedRHS.id === link.pairtype_id);
            });
        }

        function refreshState() {
            if (!scope.snapIn) {
                // No snapin active
                scope.isEnabled = true;
                scope.canComplete = false;
            }
            else if (scope.snapIn.name !== 'query') {
                // Some other snapin is active
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else if (scope.snapIn.fromRecord.docid === scope.record.docid) {
                // This record is selected as the 'from' record
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else if (scope.snapIn.toRecord &&
                     scope.snapIn.toRecord.docid === scope.record.docid) {
                // This record is selected as the 'to' record
                scope.isEnabled = false;
                scope.canComplete = false;
            }
            else {
                scope.isEnabled = true;
                scope.canComplete = true;
            }
        }

        scope.$on('QuerySnapInChangedEvent', refreshState);
        scope.$watch('snapIn', refreshState);
        scope.$watch('record', refreshState);
        scope.$watch('record.links', refreshState);
        refreshState();
    }

    return {
        restrict: 'E',
        scope: {
            record: '=',
            snapIn: '=',
        },
        templateUrl: 'partials/buttons/query.html',
        link: link,
    };
}
);
