angular.module('huni').controller('MyHuniCollectionsListController', [
    '$scope', 'HuniBackend',
function ($scope, huniBackend) {

    function init() {
        $scope.userAlert = {};
        $scope.getCollectionList();
    }

    $scope.getCollectionList = function() {
        $scope.status = 'loading';
        huniBackend.get('collection')
            .success(function(response) {
                $scope.collections = response;
                $scope.status = response.length === 0 ? 'empty' : 'ok';
            })
            .error(function() { $scope.status = 'error'; });
    };

    init();
}]);
