angular.module('huni').directive('loginButton', [
    '$location', '$route', 'UserMgtService',
function($location, $route, userMgt) {

    function link(scope) {

        function login() {
            // Go to the login page, with our current location as the redirect
            var redir = $location.absUrl();
            $location.path('/login').search({r: redir});
        }

        function logout() {
            userMgt.logout().then(ok => {
              $location.path('/');
            });
        }

        scope.click = function() {
            if (scope.user.loggedIn) {
                logout();
            }
            else {
                login();
            }
        }
    }

    return {
        restrict: 'E',
        scope: {
            user:       '=',
        },
        templateUrl: 'partials/buttons/login.html',
        link: link,
    };
}]);
